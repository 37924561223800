import React from 'react'
import '../../css/dashboard.css';
import { connect } from "react-redux";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartLegend,
    ChartTooltip,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartAxisDefaults
} from '@progress/kendo-react-charts';
import 'hammerjs';

export class LineChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesVisible: true,
            graph_type: '',
        }

    }

    render() {

        let { estimateDashboard } = this.props.estimateDashboard;

        estimateDashboard.value = estimateDashboard.value ? estimateDashboard.value : "75"
        if (this.props.estimateDashboard.name === "comparison_benchmark_hard_code_value") {
            estimateDashboard.value = this.props.estimateDashboard.value
        }

        if (estimateDashboard.message) {

            var comparisonCash = estimateDashboard.data.comparison['cash'] || "";
            var comparisonEquity = estimateDashboard.data.comparison['equity'] || "";
            var comparisonTotal = estimateDashboard.data.comparison['total'] || "";

            var compensationCash = estimateDashboard.data.compensation['cash'] || "";
            var compensationEquity = estimateDashboard.data.compensation['equity'] || "";
            var compensationTotal = estimateDashboard.data.compensation['total'] || "";

            var offerCash = estimateDashboard.data.offer['cash'] || "";
            var offerEquity = estimateDashboard.data.offer['equity'] || "";
            var offerTotal = estimateDashboard.data.offer['total'] || "";
            // }

        }

        const Offer = [offerCash, offerEquity, offerTotal];
        const Comparison = [comparisonCash, comparisonEquity, comparisonTotal];
        const Compensation = [compensationCash, compensationEquity, compensationTotal];

        const { seriesVisible } = this.state;
        const categories = ['Cash', 'Equity', 'Total'];
        const label = {
            font: "16px sans-serif"
        };
        return (
            <div className="" >
                <Chart style={{ padding: "10px 15px 10px 10px" }} pannable={{ lock: 'y' }} zoomable={false} onLegendItemClick={this.onLegendItemClick} >
                    <ChartTooltip format="c0" shared={true} />
                    <ChartAxisDefaults labels={{ format: 'c0' }} />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem categories={categories} />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem type="line" data={Comparison} visible={seriesVisible} style="smooth" name="Comparison" color="#809EB0"></ChartSeriesItem>
                        <ChartSeriesItem type="line" data={Offer} style="smooth" name="Offer" color="#FEAA62">
                            <ChartSeriesLabels format="c0" />
                        </ChartSeriesItem>
                        <ChartSeriesItem type="line" data={Compensation} style="smooth" name="Competition" color="#58628f"></ChartSeriesItem>
                    </ChartSeries>
                    <ChartLegend labels={label} position="bottom" orientation="horizontal" />
                </Chart>
            </div >
        )
    }
}


const mapStateToProps = state =>
    ({
        estimateDashboard: state.estimateDashboard,
    });
export default connect(mapStateToProps
)(LineChartComponent);
