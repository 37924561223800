import axios from "axios";
import {
  SET_CURRENT_CANDIDATE_OFFERS,
  GET_CANDIDATE_OFFERS,
  GET_SELECTED_CANDIDATES_DATA,
  CLEAR_ERRORS
} from "./types";

export const fnGetAllCandidateOffers = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidate-offer-get-grid?id=" + id)
    .then(res => {
      const CandidateOfferListData = res.data.response;
      const CandidateOfferList = []
      for (let i = 0; i < CandidateOfferListData.length; i++) {
        CandidateOfferList.push(CandidateOfferListData[i])
      }
      dispatch(setGetAllCandidateOffers(CandidateOfferList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export const fnCandidateOfferData = (candidateOfferData, history) => dispatch => {
  dispatch(clearErrors());

  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-candidate-offer-get-grid", candidateOfferData)
    .then(res => {
      dispatch(setCurrentCandidateOffers(res));
      if (res.err) {
        console.log(res.err)
      } else {
        history.push("/home/my-offers")
      }
    })
    .catch(err => { console.log(err) });
};

export const fnOfferAcceptOrReject = (offerAcceptData, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidate-offer-get-grid-action-button", offerAcceptData)
    .then(res => {
      dispatch(setCurrentCandidateOffers(res));
      if (res.err) {
        console.log(res.err)
      } else {
        history.push("/home/my-offers")
      }
    })
    .catch(err => { console.log("err", err) });
};

// candidate offer selected candidate data
export const candidateDataAction = (candidate_pool_total_value, offer_total_dollar_value, offer_base, offer_annual_bonus, offer_equity_dollar_value, offer_base_percentile, offer_bonus_percentile, offer_Equity_percentile, offer_finalist, offer_offerStrength, offer_recommended_total, role_base, role_bonus, role_equity) => dispatch => {

  dispatch(clearErrors());
  dispatch(setCandidateDataAction(candidate_pool_total_value, offer_total_dollar_value, offer_base, offer_annual_bonus, offer_equity_dollar_value, offer_base_percentile, offer_bonus_percentile, offer_Equity_percentile, offer_finalist, offer_offerStrength, offer_recommended_total, role_base, role_bonus, role_equity));
};

//UserProfile
export const setCurrentCandidateOffers = (users) => {
  return {
    type: SET_CURRENT_CANDIDATE_OFFERS,
    payload: users
  };
};

export const setGetAllCandidateOffers = (candidate_offers) => {
  return {
    type: GET_CANDIDATE_OFFERS,
    payload: candidate_offers,
  };
};


export const setCandidateDataAction = (candidate_pool_total_value, offer_total_dollar_value, offer_base, offer_annual_bonus, offer_equity_dollar_value, offer_base_percentile, offer_bonus_percentile, offer_Equity_percentile, offer_finalist, offer_offerStrength, offer_recommended_total, role_base, role_bonus, role_equity) => {
  return {
    type: GET_SELECTED_CANDIDATES_DATA,
    candidate_pool_total_value: candidate_pool_total_value,
    offer_total_dollar_value: offer_total_dollar_value,
    offer_base: offer_base,
    offer_annual_bonus: offer_annual_bonus,
    offer_equity_dollar_value: offer_equity_dollar_value,
    offer_base_percentile: offer_base_percentile, offer_bonus_percentile: offer_bonus_percentile, offer_Equity_percentile: offer_Equity_percentile,
    offer_finalist: offer_finalist,
    offer_offerStrength: offer_offerStrength,
    offer_recommended_total: offer_recommended_total,
    role_base: role_base, role_bonus: role_bonus, role_equity: role_equity
  };
};
// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};