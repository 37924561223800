import React from 'react'
import '../../../css/dashboard.css';
import initialStateParams from '../../../validation/validationParams.json';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ExploreDashBoardInputs from './ExploreDashBoardInputs';
import LineChartComponent from '../LineChart';
import RightSide from '../RightSideDashboard';
import {
    getHRDetails
} from "../../dropdown/lookups";
// import ExploreDashboardHead from './ExploreDashboardHead';

export class RateMyOfferDashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialStateParams.estimateDashBoard
    }
    componentDidMount() {
        getHRDetails(this.props.auth.user._id).then(response => {
            if (response.data) {
                this.setState({
                    Company: response.data.msg.company_name,
                    Stage: response.data.msg.company_stage,
                    Region: response.data.msg.role_region,
                    MarketSpace: response.data.msg.company_market_space,
                    url: this.props.location.pathname
                })
            }

        }).catch(error => console.log(error));
    }
    render() {
        var inputValues = {
            Company: this.state.Company,
            Stage: this.state.Stage,
            Region: this.state.Region,
            MarketSpace: this.state.MarketSpace,
            url: this.props.location.pathname
        }

        return (
            <div className="container dashboard-container">
                <div className="row">
                    <div className="col-md-9 dashboard-left">
                        {/* <ExploreDashboardHead headParams={this.state} /> */}
                        {this.state.Company && <ExploreDashBoardInputs value={initialStateParams.estimateDashBoard} inputValues={inputValues} />}
                        <LineChartComponent value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                    </div>
                    <div className="col-md-3 dashboard-right">
                        <RightSide value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                    </div>
                </div>
            </div>
        )
    }
}

RateMyOfferDashBoard.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state =>
    ({
        auth: state.auth,
        errors: state.errors,
        estimateDashboard: state.estimateDashboard,
        lookups: state.lookups
    });
export default connect(mapStateToProps
)(RateMyOfferDashBoard);
