import * as React from 'react';
import { Link } from "react-router-dom";
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import '../../../css/Addhrform.css';
import FormValidator from '../../../validation/FormValidator'
import validationParams from '../../../validation/validationParams.json'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCompanyLookUpData } from "../../../actions/lookupAction"
import { FnInsertHiringTeamMembers, fnGetRoleHiringTeamMember, FnUpdateHiringTeamMembers } from "../../../actions/roleHiringTeamMemberAction"
import { companyDataSort } from "../../dropdown/lookupSortingData";
export class AddnewHRform extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.AddnewHRformValidations);
    this.state = {
      success: false,
      first_name: "",
      last_name: "",
      email: "",
      company_name: "",
      message: "",
      validation: this.validator.valid()
    };
    this.submitted = false;
    this.RoleHrFormSubmit = this.RoleHrFormSubmit.bind(this);
    this.props.getCompanyLookUpData();

    if (this.props.match.params.new_hire_id) {
      const getRoleHrData = {
        id: this.props.match.params.id,
        new_hire_id: this.props.match.params.new_hire_id
      };
      fnGetRoleHiringTeamMember(getRoleHrData).then(response => {
        const userData = response.data.response;
        this.setState({
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          company_name: userData.company_name,

        });
      }).catch(error => console.log(error));
    }
  }
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };
  companyChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
      companyLookupData: companyDataSort(value, this.props.lookups.companyLookUp.data)
    });
  }

  RoleHrFormSubmit = (event) => {
    event.preventDefault();

    const userData = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      company_name: this.state.company_name,
      hr_id: this.props.auth.user._id,
      new_hire_id: this.props.match.params.id
    };

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      if (this.props.match.params.new_hire_id) {
        const updateData = {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          company_name: this.state.company_name,
          invitee_hiring_member_id: this.props.match.params.id,
          new_hire_id: this.props.match.params.new_hire_id
        };
        this.props.FnUpdateHiringTeamMembers(updateData, this.props.history, this.props.match.params.new_hire_id)
      } else {
        this.props.FnInsertHiringTeamMembers(userData, this.props.history, this.props.match.params['id'])
      }

    }
    setTimeout(() => {
      this.setState({
        message: ""
      });
    }, 2000);
  }

  render() {
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation
    let cancelButtonUrl = "";
    if (this.props.match.params.new_hire_id) {
      cancelButtonUrl = "/home/add-hr?id=" + this.props.match.params.new_hire_id;
    } else {
      cancelButtonUrl = "/home/add-hr?id=" + this.props.match.params['id'];
    }
    console.log(cancelButtonUrl)
    return (
      <div>
        <div className="container-fluid card-layout">
          <div className="row card-block">
            <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">
              <form className="k-form" onSubmit={this.RoleHrFormSubmit} >
                <fieldset style={{ marginBottom: "0px" }}>
                  <h1 className="h1s">Allow access to hiring team</h1>
                  <h3 className="h3s"> HIRING TEAM MEMBER DETAILS</h3>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5"><Input
                      name="first_name"
                      type="text"
                      style={{ width: '100%' }}
                      onChange={this.onChange}
                      label="FirstName *"
                      value={this.state.first_name}
                    /></div>

                  </div>
                  <div className="row" style={{
                    color: "red"
                  }}>
                    <div className="col-md-1 col-sm-0 col-xs-0 col-lg-1"></div>
                    <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                      {validation.first_name.message}</div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="last_name"
                        type="text"
                        style={{ width: '100%' }}
                        onChange={this.onChange}
                        label="Last Name *"
                        value={this.state.last_name}
                      />
                    </div>
                  </div>
                  <div className="row" style={{
                    color: "red"
                  }}>
                    <div className="col-md-1 col-sm-0 col-xs-0 col-lg-1"></div>
                    <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                      {validation.last_name.message}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <Input
                        name="email"
                        type="email"
                        style={{ width: '100%' }}
                        onChange={this.onChange}
                        label="Email *"
                        value={this.state.email}
                      />
                    </div>
                  </div>
                  <div className="row" style={{
                    color: "red"
                  }}>
                    <div className="col-md-1 col-sm-0 col-xs-0 col-lg-1"></div>
                    <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                      {validation.email.message}
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-5 col-sm-11 col-xs-11 col-lg-5">
                      <AutoComplete
                        name="company_name"
                        label="Company *"
                        data={this.state.companyLookupData}
                        onChange={this.companyChange}
                        value={this.state.company_name}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="row" style={{
                    color: "red"
                  }}>
                    <div className="col-md-1 col-sm-0 col-xs-0 col-lg-1"></div>
                    <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                      {validation.company_name.message}
                    </div>
                  </div>
                </fieldset>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2">
                    <Button className="mt-3 col-md-12 col-xs-12 col-sm-12 col-lg-12" type="submit" primary={true} >Submit</Button>
                  </div>
                  <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2" style={{ border: "1px slid red" }}>
                    <Link type="button" to={cancelButtonUrl} className="k-button col-md-12 col-xs-12 col-sm-12 col-lg-12" style={{ marginTop: "16px" }}>CANCEL</Link>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AddnewHRform.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hiringMembers: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  AddnewHRform: state.AddnewHRform,
  hiringMembers: state.hiringMembers,
  lookups: state.lookups,
  errors: state.errors
});

export default connect(mapStateToProps, { getCompanyLookUpData, FnInsertHiringTeamMembers, FnUpdateHiringTeamMembers, fnGetRoleHiringTeamMember })(AddnewHRform);