import React, { Component } from 'react';
import {
  fnVerifyUserEmail,
  fnVerifyHiringTeamMemberEmail
} from "../../actions/authAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import '../../css/login.css';

class EmailVerifiedClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
    };

    const userData = {
      email: this.props.match.params.email,
      systemUniqueKey: this.props.match.params.systemUniqueKey,
    }
    const hiringTeamMemberData = {
      email: this.props.match.params.email,
      key: this.props.match.params.key,
    }
    if (this.props.match.params.key) {
      this.props.fnVerifyHiringTeamMemberEmail(hiringTeamMemberData, this.props.history)
    } else {
      fnVerifyUserEmail(userData).then(response => {
        this.props.history.push("/home/estimate-dashboard")
      }).catch(err => console.log(err));
    }
  }

  render() {
    return (
      <div className="container-fluid">
      </div>
    );
  }
}

EmailVerifiedClass.propTypes = {
  auth: PropTypes.object.isRequired,
  fnVerifyHiringTeamMemberEmail: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    errors: state.errors
  });

export default connect(mapStateToProps, { fnVerifyUserEmail, fnVerifyHiringTeamMemberEmail }
)(EmailVerifiedClass);