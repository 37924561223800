import {
  SET_CURRENT_ESTIMATE_DASHBOARD,
  ESTIMATE_DASHBOARD_LOADING,
  SET_CURRENT_INPUT_ETIMATE_DASHBOARD,
  SET_CURRENT_DIALOGBOX_OFFER_DASHBOARD,
  SET_CURRENT_PERCENTAGE_HIDDEN_OFFER_DASHBOARD,
  SET_CURRENT_ADD_MEMBER_ICON_OFFER_DASHBOARD,
  SET_CURRENT_ADD_UPDATE_TMP_POWER_DASHBOARD,
  SET_ROLE_INSERT_FLAG_OFFER_DASHBOARD
} from "../actions/types";

const initialState = {
  estimateDashboard: {},
  name: "",
  value: "",
  togglename: "",
  booleanvalue: "",
  hiddenName: "",
  addMemberInviteButton: "",
  addUpdateTmpPowerDashboard: "",
  roleInsertFlag: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ESTIMATE_DASHBOARD_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_CURRENT_ESTIMATE_DASHBOARD:
      return {
        ...state,
        estimateDashboard: action.payload,
        loading: false
      };
    case SET_CURRENT_INPUT_ETIMATE_DASHBOARD:
      return {
        ...state,
        name: action.name,
        value: action.value,
        loading: false
      };
    case SET_CURRENT_DIALOGBOX_OFFER_DASHBOARD:
      return {
        ...state,
        togglename: action.name,
        booleanvalue: action.value,
        loading: false
      };
    case SET_CURRENT_PERCENTAGE_HIDDEN_OFFER_DASHBOARD:
      return {
        ...state,
        hiddenName: action.name,
        loading: false
      };

    case SET_CURRENT_ADD_MEMBER_ICON_OFFER_DASHBOARD:
      return {
        ...state,
        addMemberInviteButton: action.name,
        loading: false
      };
    case SET_CURRENT_ADD_UPDATE_TMP_POWER_DASHBOARD:
      return {
        ...state,
        addUpdateTmpPowerDashboard: action.name,
        loading: false
      };
    case SET_ROLE_INSERT_FLAG_OFFER_DASHBOARD:

      return {
        ...state,
        roleInsertFlag: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
