import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction, offerDashDialogBoxAction } from "../../../../actions/dashboard";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

export class OfferDashOfferResetOfferButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true
    }
  }

  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible
    });
    this.props.offerDashDialogBoxAction(this.state.visible, false)
  }
  render() {
    if (this.props.estimateDashboard.togglename === "reset_offer") {
      this.state.visible = true
    } else {
      this.state.visible = false
    }
    return (
      <div>
        {this.state.visible && <Dialog className="div-modal" title={"Reset Offer..."} onClose={this.toggleDialog}>
          <p style={{ margin: "25px", fontSize: "16px" }}>Do you want to reset the offer, to last saved state. You will loose the changes made. Are you sure you wish to proceed?</p>
          <div>
            <DialogActionsBar className="modal-button">
              <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog}>No</button>
              <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog}>Yes</button>
            </DialogActionsBar>
          </div>
        </Dialog>}
      </div >

    )
  }
}
const mapStateToProps = state =>
  ({
    estimateDashboard: state.estimateDashboard,
  });
export default connect(mapStateToProps, { inputValueAction, offerDashDialogBoxAction }
)(OfferDashOfferResetOfferButton);
