import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { SET_CURRENT_USER, CLEAR_ERRORS, SET_CURRENT_PROFILE } from "./types";


export const registeruser = (userData, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-sec-singup-post", userData)
    .then(res => {
      if (res.data.msg) {
        const message = res.data.msg;
        const decoded = ""
        dispatch(setCurrentUserLogin(decoded, message));
      } else {
        const token = res.data.token;
        const names = res.data.data.first_name + " " + res.data.data.last_name;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("payload", names);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentUserLogin(decoded, names));
        if(res.data.data.profile_updated === false) {
          history.push("/home/my-profile")
        } 
        else {
          history.push("home/estimate-dashboard")
        }
      }
    })
    .catch(err => console.log(err)
    );
};


export const loginUser = (userData, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-sec-singin-post", userData)
    .then(res => {
      if (res.data.msg) {
        const message = res.data.msg;
        const decoded = ""
        dispatch(setCurrentUserLogin(decoded, message));
      } else {
        const token = res.data.token;
        const names = res.data.data.first_name + " " + res.data.data.last_name;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("payload", names);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentUserLogin(decoded, names));
        if (res.data.data.email_verified === false) {
          history.push("/verify-email")
        } else if(res.data.data.profile_updated === false) {
          history.push("/home/my-profile")
        } 
        else {
          history.push("home/estimate-dashboard")
        }
      }
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export function fnChangePassword(userData) {
  return axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-sec-password-change-post", userData)
}

export function fnforgetPassword(userData) {
  return axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-sec-password-forgot-post", userData)
}

export function fnResetPassword(userData) {
  return axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-sec-password-reset-post", userData)
}

export function sendEmailVerification(userData) {
  return axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-sec-resend-email-post", userData)
}

export function fnVerifyUserEmail(userData) {

  return axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-sec-email-verified-post", userData)
}

export const fnVerifyHiringTeamMemberEmail = (userData, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-sec-keylogin-post", userData)
    .then(res => {
      const token = res.data.token;
      const names = res.data.data.first_name + " " + res.data.data.last_name;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("payload", names);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUserLogin(decoded, names));
      if (res.data.data.email_verified === false) {
        history.push("/verify-email")
      } else {
        history.push("/home/estimate-dashboard")
      }
    })
    .catch(err => { console.log(err) });
};

export const HRProfile = (userData, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/my-profile", userData)
    .then(res => {
      dispatch(setCurrentUserProfile(res.data));
      if (res.data) {
        history.push("/home/estimate-dashboard")
      }
    })
    .catch(err =>
      console.log(err)
    );
};
export const setCurrentUser = decoded => {

  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const setCurrentUserLogin = (decoded, users) => {

  return {
    type: SET_CURRENT_USER,
    payload: decoded,
    userName: users
  };
};
export const setCurrentUserProfile = (users) => {

  return {
    type: SET_CURRENT_PROFILE,
    payload: users
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

export const logoutUser = (userData, history) => dispatch => {
  let data = {
    id: userData
  }
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-sec-signout-post", data)
    .then(res => {
      console.log("dispatch")
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("payload");
      setAuthToken(false);
      dispatch(setCurrentUser({}));
      history.push("/login")
    })
    .catch(err => { console.log(err) });

};