import axios from "axios";

export function getCompanyLookUp() {
  return axios
    .get(axios.defaults.baseUrl + "get-company-data")
}

export function getStageLookUp() {
  return axios
    .get(axios.defaults.baseUrl + "get-company-stage-data")
}

export function getCompanyLocationRegionData() {
  return axios
    .get(axios.defaults.baseUrl + "get-company-location-region-data")
}

export function getgraphAPIListMarketSpaces() {
  return axios
    .get(axios.defaults.baseUrl + "get-market-space-data")
}

export function getcompanyLocationNoOfEmployeesDropData() {
  return axios
    .get(axios.defaults.baseUrl + "get-company-location-no-of-employees-data")
}


export function getHRDetails(userData) {
  var id = userData;

  return axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-profile-get-form?id=" + id)
}

export function getTitleData() {
  return axios
    .get(axios.defaults.baseUrl + "get-title-data")
}

export function getFunctionData() {
  return axios
    .get(axios.defaults.baseUrl + "apl-lkp-get-function-list")
}

