export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_PROFILE = "SET_CURRENT_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const SET_CURRENT_ROLES = "SET_CURRENT_ROLES";
export const CLEAR_CURRENT_ROLES = "CLEAR_CURRENT_ROLES";
export const ROLES_LOADING = "ROLES_LOADING";
export const GET_ROLES = "GET_ROLES";
export const SET_CURRENT_ESTIMATE_DASHBOARD = "SET_CURRENT_ESTIMATE_DASHBOARD";
export const ESTIMATE_DASHBOARD_LOADING = "ESTIMATE_DASHBOARD_LOADING";
export const GET_ESTIMATE_DASHBOARD = "GET_ESTIMATE_DASHBOARD";
export const GET_HIRING_ROLE_LOOKUP = "GET_HIRING_ROLE_LOOKUP";
export const GET_STAGE_LOOKUP = "GET_STAGE_LOOKUP";
export const GET_COMPANY_LOCATION_REGION_LOOKUP = "GET_COMPANY_LOCATION_REGION_LOOKUP";
export const GET_COMPANY_RELATED_DATA_LOOKUP = "GET_COMPANY_RELATED_DATA_LOOKUP";
export const GET_MARKET_SPACE_LOOKUP = "GET_MARKET_SPACE_LOOKUP";
export const GET_Revenue_LOOKUP = "GET_Revenue_LOOKUP";
export const GET_COMPANY_NO_OF_EMPLOYEES_LOOKUP = "GET_COMPANY_NO_OF_EMPLOYEES_LOOKUP";
export const GET_COMPANY_LOOKUP = "GET_COMPANY_LOOKUP";
export const GET_TITLE_LOOKUP = "GET_TITLE_LOOKUP";
export const GET_FUNCTION_LOOKUP = "GET_FUNCTION_LOOKUP";
export const SET_CURRENT_INPUT_ETIMATE_DASHBOARD = "SET_CURRENT_INPUT_ETIMATE_DASHBOARD";
export const SET_CURRENT_INPUT_POWER_USER_DASHBOARD = "SET_CURRENT_INPUT_POWER_USER_DASHBOARD";

// Role hiring team members
export const SET_CURRENT_HIRING_TEAM_MEMBERS = "SET_CURRENT_HIRING_TEAM_MEMBERS";
export const GET_HIRING_TEAM_MEMBERS = "GET_HIRING_TEAM_MEMBERS";
export const HIRING_TEAM_MEMBERS_LOADING = "HIRING_TEAM_MEMBERS_LOADING";
export const SET_INSERT_UPDATE_HIRING_TEAM_MEMBERS = "SET_INSERT_UPDATE_HIRING_TEAM_MEMBERS";
export const GET_DEGREE_LOOKUP = "GET_DEGREE_LOOKUP";
export const GET_GRADUATION_YEAR_LOOKUP = "GET_GRADUATION_YEAR_LOOKUP";

// Role Candidate members
export const SET_CURRENT_CANDIDATE_MEMBERS = "SET_CURRENT_CANDIDATE_MEMBERS";
export const GET_ROLE_CANDIDATE = "GET_ROLE_CANDIDATE";
export const HIRING_GET_ROLE_CANDIDATE = "HIRING_GET_ROLE_CANDIDATE";
export const GET_ROLE_CANDIDATE_LOADING = "GET_ROLE_CANDIDATE_LOADING";

export const GET_CANDIDATE_FROM_OFFER_DASHBOARD = "GET_CANDIDATE_FROM_OFFER_DASHBOARD";

// Candidate Profile
export const SET_CANDIDATE_PROFILE = "SET_CANDIDATE_PROFILE";
export const GET_CANDIDATE_PROFILE_LOADING = "GET_CANDIDATE_PROFILE_LOADING";

// Candidate Offers
export const SET_CURRENT_CANDIDATE_OFFERS = "SET_CURRENT_CANDIDATE_OFFERS";
export const GET_CANDIDATE_OFFERS = "GET_CANDIDATE_OFFERS";
export const GET_CANDIDATE_OFFERS_LOADING = "GET_CANDIDATE_OFFERS_LOADING";

// lOOKUPS
export const GET_COMPANY_MARKET_STAGE_REGION_LOOKUP = "GET_COMPANY_MARKET_STAGE_REGION_LOOKUP";

// Dialog box Offer dashboard
export const SET_CURRENT_DIALOGBOX_OFFER_DASHBOARD = "SET_CURRENT_DIALOGBOX_OFFER_DASHBOARD";


// Right side percentage hidden action
export const SET_CURRENT_PERCENTAGE_HIDDEN_OFFER_DASHBOARD = "SET_CURRENT_PERCENTAGE_HIDDEN_OFFER_DASHBOARD";

// Add member icon click
export const SET_CURRENT_ADD_MEMBER_ICON_OFFER_DASHBOARD = "SET_CURRENT_ADD_MEMBER_ICON_OFFER_DASHBOARD";

// Add temproray power dashboard changes done
export const SET_CURRENT_ADD_UPDATE_TMP_POWER_DASHBOARD = "SET_CURRENT_ADD_UPDATE_TMP_POWER_DASHBOARD";



// Add Role Status done
export const GET_ROLE_STATUS_LOOKUP = "GET_ROLE_STATUS_LOOKUP";

// Add City
export const GET_CITY_LOOKUP = "GET_CITY_LOOKUP";

// Add state lookup
export const GET_STATE_LOOKUP = "GET_STATE_LOOKUP";

// Add region lookup
export const GET_REGION_LOOKUP = "GET_REGION_LOOKUP";

//Selected Candidate data
export const GET_SELECTED_CANDIDATES_DATA = "GET_SELECTED_CANDIDATES_DATA";

// CREATE OFFER DASHBOARD
export const SET_CURRENT_ADD_UPDATE_CREATE_OFFER_DASHBOARD = "SET_CURRENT_ADD_UPDATE_CREATE_OFFER_DASHBOARD";


// ROLE INSERT OFFER DASHBOARD
export const SET_ROLE_INSERT_FLAG_OFFER_DASHBOARD = "SET_ROLE_INSERT_FLAG_OFFER_DASHBOARD";

// RIGHT SIDE DATA VALUES
export const SET_ROLE_VALUES_RIGHT_SIDE_OFFER_DASHBOARD = "SET_ROLE_VALUES_RIGHT_SIDE_OFFER_DASHBOARD";
