import React from 'react'
import '../../../css/dashboard.css';
import { connect } from "react-redux";
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import {
    getTitleLookUpData,
    getFunctionLookUpData,
    getCompanyLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getStageLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData

} from "../../../actions/lookupAction";
import { inputValueAction } from "../../../actions/dashboard";
import { fnEstimateDashboard } from "../../../actions/dashboard";
import {  companyDataSort, stageDataSort } from "../../dropdown/lookupSortingData";
class OfferDashBoardInputs extends React.Component {
    constructor(props) {
        super(props);
        this.props.getTitleLookUpData();
        this.props.getFunctionLookUpData();
        this.props.getCompanyLookUpData();
        this.props.getgraphAPIListMarketSpaceLookUpData();
        this.props.getCompanyLocationRegionLookUpData();
        this.props.getcompanyLocationNoOfEmployeesLookUpData();
        this.props.getStageLookUpData();
        this.state = {
            Title: this.props.value.Title,
            Function: this.props.value.Function,
            role_years: this.props.value.role_years,
            base_percentile: "75", // this.props.value.base_percentile,
            Company: this.props.value.Company,
            MarketSpace: this.props.value.MarketSpace,
            Region: this.props.value.Region,
            Stage: this.props.value.Stage,
            isBasePercentileFieldVisible: true,
            pick_benchMark: "",
            comparison_benchmark_hard_code_value: "",
            base: ""
        };
        const userDashBoardData = {
            Title: this.props.value.Title,
            Function: this.props.value.Function,
            role_years: this.props.value.role_years,
            base_percentile: (this.state.base_percentile / 100).toString(),
            MarketSpace: this.props.inputValues.MarketSpace,
            Company: this.props.inputValues.Company,
            Region: this.props.inputValues.Region,
            Stage: this.props.inputValues.Stage
        }

        this.props.fnEstimateDashboard(userDashBoardData);
    }

    onChange = e => {
        const value = e.target.value;
        this.state[e.target.name] = value;

        this.setState({
            [e.target.name]: value,
            companyLookUpData: companyDataSort(value, this.props.lookups.companyLookUp.data),
            stageLookupData: stageDataSort(value, this.props.lookups.stageLookUp.data.sort().filter(function (el) {
                return el != "PUBLIC";
            }))
        });

        this.props.inputValueAction(e.target.name, e.target.value);
        if (e.target.name === "comparison_benchmark_hard_code_value") {
            this.props.inputValueAction(e.target.name, 75);
            if (e.target.value === "75th Percentile") {
                this.state.base_percentile = "75"

                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your Company Rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {

                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
            if (e.target.value === "60th Percentile") {
                this.state.base_percentile = "60"
                this.props.inputValueAction(e.target.name, 60);
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your Company Rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
            if (e.target.value === "50th Percentile") {
                this.props.inputValueAction(e.target.name, 50);
                this.state.base_percentile = "50"
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your Company Rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
        }

        if (e.target.value === "Custom percentile") {
            this.props.inputValueAction(e.target.name, 50);
            this.state.base_percentile = "50"
            this.setState({
                isBasePercentileFieldVisible: true
            });
        } else if (e.target.value === "Your Company Rank") {
            this.props.inputValueAction(e.target.name, 0);
            this.state.base_percentile = ""
            this.setState({
                isBasePercentileFieldVisible: true
            });
        } else if (e.target.value === "Competition") {
            this.props.inputValueAction(e.target.name, 80);
            this.state.base_percentile = "80"
            this.setState({
                isBasePercentileFieldVisible: false
            });
        } else if (e.target.value === "60th percentile") {
            this.props.inputValueAction(e.target.name, 60);
            this.state.base_percentile = "60"
            this.setState({
                isBasePercentileFieldVisible: false
            });
        }


        const userDashBoardData = {
            Title: this.state.Title,
            Function: this.state.Function,
            role_years: this.state.role_years,
            base_percentile: (this.state.base_percentile / 100).toString(),
            MarketSpace: this.state.MarketSpace,
            Company: this.state.Company,
            Region: this.state.Region,
            Stage: this.state.Stage
        }

        this.props.fnEstimateDashboard(userDashBoardData);
    }
    render() {

        if (this.props.estimateDashboard.name === "pick_benchMark" || this.props.estimateDashboard.name === "donutarrow" || this.props.estimateDashboard.name === "comparison_benchmark_hard_code_value") {
            this.state.base_percentile = this.props.estimateDashboard.value
        }
        let company_region_lookup_data = [];
        if (this.props.lookups.companyRegionLookUp.length !== undefined) {
            company_region_lookup_data = this.props.lookups.companyRegionLookUp;
        }


        return (
            <div className="">
                <div className="" style={{ height: "20px", marginTop: "10px" }}> <div className="float-right power_user_dashboard-icon-save"><i title="Save Offer" className="fa fa-floppy-o" aria-hidden="true"
                    style={{
                        margin: "auto", color: "rgba(255,255,255,0.87)", fontSize: "24px", lineHeight: "1", marginLeft: "10px", marginTop: "6px"
                    }}
                >
                </i></div></div>
                <div className="row dashboard-input-wrapper" style={{ paddingTop: "0px", marginTop: "30px" }}>
                    <div className="col-md-3">
                        <AutoComplete
                            name="Company"
                            onChange={this.onChange}
                            required={true}
                            data={this.state.companyLookUpData}
                            label="Company"
                            value={this.state.Company}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3">
                        <AutoComplete
                            name="MarketSpace"
                            onChange={this.onChange}
                            required={true}
                            data={this.props.lookups.marketSpaceLookUp.data}
                            label="Market Space"
                            value={this.state.MarketSpace}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3">
                        <AutoComplete
                            name="Region"
                            onChange={this.onChange}
                            required={true}
                            data={company_region_lookup_data}
                            label="Region"
                            value={this.state.Region}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3">
                        <AutoComplete
                            name="Stage"
                            onChange={this.onChange}
                            required={true}
                            data={this.state.stageLookupData}
                            label="Stage"
                            value={this.state.Stage}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
                <div className="row dashboard-input-wrapper">
                    <div className="col-md-3">
                        <Input
                            name="base"
                            onChange={this.onChange}
                            data={this.props.lookups.stageLookUp.data}
                            placeholder="$X,XXX"
                            label="Base"
                            value={this.state.base}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3">
                        <Input
                            name="Bonus"
                            onChange={this.onChange}
                            data={this.props.lookups.stageLookUp.data}
                            label="Bonus"
                            placeholder="$X,XXX"
                            value={this.state.Bonus}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3">
                        <Input
                            name="equity"
                            onChange={this.onChange}
                            data={this.props.lookups.stageLookUp.data}
                            label="Equity %"
                            placeholder="XX%"
                            value={this.state.equity}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3">
                        <Input
                            name="annual_equity"
                            onChange={this.onChange}
                            data={this.props.lookups.stageLookUp.data}
                            label="Annual Equity"
                            placeholder="$X,XXX"
                            value={this.state.equity}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state =>
    ({
        lookups: state.lookups,
        estimateDashboard: state.estimateDashboard,
        powerUserDashBoard: state.powerUserDashBoard
    });
export default connect(mapStateToProps, {
    getTitleLookUpData,
    getFunctionLookUpData,
    getCompanyLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData,
    getStageLookUpData,
    fnEstimateDashboard,
    inputValueAction

}
)(OfferDashBoardInputs);
