import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Link } from "react-router-dom";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import '../../css/modal.css';
import '@progress/kendo-ui';
import { Menu, MenuItem, SubMenu } from '@progress/kendo-layout-react-wrapper';

export default function MyRoleButtons(editField) {
    
    let visible= false;
    let url = '';

    function deleteSubmit(id, visible_value){
        url = id;
        visible= visible_value;
    }
    
    return class extends GridCell {   
      render() {
        const UPDATE_URL = "/home/update-role?id="+this.props.dataItem["_id"]
        const DELETE_URL = "/home/delete-role?id="+this.props.dataItem["_id"]
        const ADD_CANDIDATE_URL = "/home/add-candidate?id="+this.props.dataItem["_id"]
        const ADD_HR_URL = "/home/add-hr?id="+this.props.dataItem["_id"]
            
          return !this.props.dataItem[editField]
            ? (
                <div>
                  <Menu>
                    <MenuItem> <i class="fa fa-list" aria-hidden="true"></i>
                      <SubMenu>
                        <MenuItem>
                          <Link type="button" 
                                to={UPDATE_URL}  
                                title="Edit"                   
                                className="k-button" >
                            <span className="k-icon k-i-edit .k-i-pencil"></span>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link type="button" 
                                title="Delete" 
                                className="k-button" 
                                id={DELETE_URL} 
                                onClick={() => deleteSubmit(DELETE_URL, true)}>
                            <span className="k-icon k-i-delete .k-i-trash"></span>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link type="button" 
                                to={ADD_HR_URL} 
                                title="Add HR"                    className="k-button" >
                          <span className="k-icon k-i-user"></span>
                          </Link>
                        </MenuItem>
                      <MenuItem>
                        <Link type="button" 
                              to={ADD_CANDIDATE_URL} 
                              title="Add Candidates" 
                              className="k-button" >
                          <span className="k-icon k-i-myspace"></span>
                        </Link>
                      </MenuItem>
                    </SubMenu>
                    </MenuItem>
                  </Menu>
                  
                    {visible && <Dialog title={"Please confirm"}>
                         <p style={{ margin: "25px", textAlign: "center" }}>
                             Are you sure you want to continue?
                         </p>
                       <DialogActionsBar>
                         <Link className="k-button" onClick={() => deleteSubmit('', false)}>No</Link>
                         <Link type="submit" className="k-button" to={url}>Yes</Link> 
                       </DialogActionsBar>
                     </Dialog>} 
   
                </div>
            )
            : null;
        }
    }
};