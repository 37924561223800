import React from 'react';
import { Upload } from '@progress/kendo-react-upload';



class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          visible: true
        }
      }
    
    
    render() {
        return (
            
                <Upload
                batch={false}
                multiple={true}
                defaultFiles={[]}
                withCredentials={false}
                />
              
        );
    }
}
export default FileUpload;