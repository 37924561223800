import {
  SET_CURRENT_ROLES,
  ROLES_LOADING,
  GET_ROLES
} from "../actions/types";

const initialState = {
  role: {},
  rolesData: null,
  roleBaseBonusEquityData: "",
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROLES_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_CURRENT_ROLES:
      return {
        ...state,
        role: action.payload.data,
        loading: true
      };
    case GET_ROLES:
      return {
        ...state,
        rolesData: action.payload.splice(action),
        loading: true
      };

    default:
      return state;
  }
}
