import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/bootstrap.min.css';
import '@progress/kendo-theme-material/dist/all.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { HashRouter } from "react-router-dom";
import axios from 'axios';
import store from './store';
axios.defaults.baseUrl = window.location.origin + '/';
ReactDOM.render(
    <Provider store={store}>
        <HashRouter >
            <App />
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);
serviceWorker.unregister();
