import React from 'react'
import '../../css/dashboard.css';
import initialStateParams from '../../validation/validationParams.json';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LineChartComponent from './LineChart';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import OfferRightSide from '../dashboard/RateOfferdashboard/OfferRightSideDashboard';
import OfferDashboardInputs from '../dashboard/RateOfferdashboard/offerDashboardInputs'
import {
    getHRDetails
} from "../dropdown/lookups";
import { fnGetNewHires } from "../../actions/roleActions";

export class DashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialStateParams.estimateDashBoard


    }
    handleSelect = (e) => {
        this.setState({ selected: e.selected })
    }
    componentDidMount() {
        if (this.props.location.search !== "") {
            fnGetNewHires(this.props.location.search.slice(9)).then(response => {
                if (response.data) {
                    this.setState({
                        Company: response.data.response.role_company,
                        Stage: response.data.response.role_stage,
                        Region: response.data.response.role_region,
                        MarketSpace: response.data.response.role_market_space
                    })
                }
            }).catch(error => console.log(error));
        } else {
            getHRDetails(this.props.auth.user._id).then(response => {
                if (response.data) {
                    this.setState({
                        Company: response.data.msg.company_name,
                        Stage: response.data.msg.company_stage,
                        Region: response.data.msg.role_region,
                        MarketSpace: response.data.msg.company_market_space
                    })
                }
            }).catch(error => console.log(error));
        }

    }
    render() {
        var inputValues = {
            Company: this.state.Company,
            Stage: this.state.Stage,
            Region: this.state.Region,
            MarketSpace: this.state.MarketSpace
        }
        var history = this.props.history
        var location = this.props.location

        if (this.props.location.search !== "") {
            var user_id = {
                user_id: this.props.location.search.slice(9)
            }
        }

        return (
            <TabStrip className="TabstripCss" selected={this.state.selected} onSelect={this.handleSelect} className="col-sm-12 col-xs-12 col-md-12 col-lg-12" style={{ margin: "2px", marginLeft: "0px", paddingLeft: "75px", marginTop: "25px" }
            }>
                <TabStripTab title="Profile" >
                  <p>Tab 1 Content</p>
                </TabStripTab>
                <TabStripTab title="Explore">
                  <p>Tab 2 Content</p>
                </TabStripTab>
                <TabStripTab title="Rate Offer">
                <div className="container dashboard-container" style={{ padding: "0px", paddingTop: "0px" }}>
                        <div className="row" style={{ border: "1px solid #d9d9d9", margin: "0px" }}>
                            <div className="col-md-9 dashboard-left">
                                {this.state.Company && <OfferDashboardInputs value={initialStateParams.estimateDashBoard} inputValues={inputValues} />}
                                <LineChartComponent value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                            </div>
                            <div className="col-md-3 dashboard-right">
                                <OfferRightSide value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                            </div>
                            
                        </div>
                    </div>
                </TabStripTab>
                <TabStripTab title="Company Dashboard">
                    <p>Tab 4 Content</p>
                </TabStripTab>
            </TabStrip >

        )
    }
}

DashBoard.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state =>
    ({
        auth: state.auth,
        errors: state.errors,
        estimateDashboard: state.estimateDashboard,
        lookups: state.lookups
    });
export default connect(mapStateToProps
)(DashBoard);
