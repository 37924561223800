import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../../css/register.css';
//import { cursorTo } from 'readline';

class RegisterMasterClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errors: {}
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.history.push("/register-with-email")
  }
  render() {

    return (
      <div className="container-fluid landing">
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-10 offset-md-0">
              <div className="signup-card-box"> <br /><br />
                <p className="master-register-paragraph">
                  Create and personalize your Apollo profile.
                </p>
                <div className="col-md-12">
                  <input type="submit" onClick={this.handleSubmit} 
                        value="Signup with Email" 
                        style={{width:"100%",
                                color: "white",
                                fontWeight: "350px",
                                backgroundColor: "#22aae4",
                                display: "inline-block",
                                border: "1px solid transparent",
                                formatNumber: "400px",
                                height: "50px",
                                fontSize: "24px",
                                borderRadius: "4px",
                                paddingTop: "5px",
                              }}
                  />
                  <h2 className="register-master-header"> or </h2>
                </div>
                <div className="col-md-12">
                  <Link to="/linkedinAuth"
                      type="button"
                      className="k-button master-signup-linkedIn"
                    >
                      <span
                        className="k-icon k-i-linkedin master-signup-linkedIn-icon"
                      >
                      </span>
                      <div className="master-signup-linkedIn-line">
                      </div>
                      <span className="master-signup-linkedIn-button-font">
                        Signup with LinkedIn
                      </span>
                    </Link>
                </div>
                <br /><br />
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}
export default RegisterMasterClass;
