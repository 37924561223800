import {
  GET_HIRING_ROLE_LOOKUP,
  GET_STAGE_LOOKUP,
  GET_COMPANY_LOCATION_REGION_LOOKUP,
  GET_COMPANY_RELATED_DATA_LOOKUP,
  GET_MARKET_SPACE_LOOKUP,
  GET_Revenue_LOOKUP,
  GET_COMPANY_NO_OF_EMPLOYEES_LOOKUP,
  GET_COMPANY_LOOKUP,
  GET_TITLE_LOOKUP,
  GET_FUNCTION_LOOKUP,
  GET_DEGREE_LOOKUP,
  GET_GRADUATION_YEAR_LOOKUP,
  GET_COMPANY_MARKET_STAGE_REGION_LOOKUP,
  GET_ROLE_STATUS_LOOKUP,
  GET_CITY_LOOKUP,
  GET_STATE_LOOKUP,
  GET_REGION_LOOKUP
} from "../actions/types";

const initialState = {
  hiringRoleLookUp: {},
  companyLookUp: {},
  stageLookUp: {},
  companyLocationLookUp: {},
  companyStatesLookUp: {},
  companyRegionLookUp: {},
  locationRegiongCityLookUp: {},
  marketSpaceLookUp: {},
  revenueLookUp: {},
  companyForEmployeesLookUp: {},
  noOfEmployeesCompanyNameLookUp: [],
  noOfEmployeesLookUp: {},
  companyEmployeeIdLookUp: {},
  titleLookUp: {},
  functionLookUp: {},
  degreeLookUp: {},
  graduationYearLookUp: {},
  companyMarketStageRegionLookUp: {},
  locationLookUp: {},
  statesLookUp: {},
  regionLookUp: {},
  marketSpaceDataLookUp: {},
  stageLookDataUp: {},
  employeesLookUp: {},
  competitors01LookUp: {},
  companyRelatedData: {},
  roleStatusLookUp: {},
  cityLookUp: {},
  stateLookUp: {},
  regionLookUpData: {},
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HIRING_ROLE_LOOKUP:
      return {
        ...state,
        hiringRoleLookUp: action.payload,
        loading: false
      };
    case GET_COMPANY_LOOKUP:
      return {
        ...state,
        companyLookUp: action.payload,
        loading: false
      };
    case GET_FUNCTION_LOOKUP:
      return {
        ...state,
        functionLookUp: action.payload,
        loading: false
      };
    case GET_TITLE_LOOKUP:
      return {
        ...state,
        titleLookUp: action.payload,
        loading: false
      };
    case GET_STAGE_LOOKUP:
      return {
        ...state,
        stageLookUp: action.payload,
        loading: false
      };
    case GET_COMPANY_LOCATION_REGION_LOOKUP:
      return {
        ...state,
        companyLocationLookUp: action.Locations,
        companyStatesLookUp: action.States,
        companyRegionLookUp: action.Regions,
        locationRegiongCityLookUp: action.locationRegiongCity,
        loading: false
      };
    case GET_COMPANY_RELATED_DATA_LOOKUP:
      return {
        ...state,
        locationLookUp: action.Locations,
        statesLookUp: action.States,
        regionLookUp: action.Regions,
        marketSpaceDataLookUp: action.MarketSpace,
        stageLookDataUp: action.Stage,
        employeesLookUp: action.Employees,
        competitors01LookUp: action.Competitors01,
        companyRelatedData: action.companyRelatedData,
        loading: false
      };
    case GET_MARKET_SPACE_LOOKUP:
      return {
        ...state,
        marketSpaceLookUp: action.payload,
        loading: false
      };
    case GET_Revenue_LOOKUP:
      return {
        ...state,
        revenueLookUp: action.payload,
        loading: false
      };
    case GET_COMPANY_NO_OF_EMPLOYEES_LOOKUP:
      return {
        ...state,
        companyForEmployeesLookUp: action.companyForEmployess,
        noOfEmployeesLookUp: action.noOfEmployees,
        noOfEmployeesCompanyNameLookUp: action.companyForEmployess,
        companyEmployeeIdLookUp: action.companyEmployeeId,
        loading: false
      };
    case GET_DEGREE_LOOKUP:
      return {
        ...state,
        degreeLookUp: action.payload,
        loading: false
      };
    case GET_GRADUATION_YEAR_LOOKUP:
      return {
        ...state,
        graduationYearLookUp: action.payload,
        loading: false
      };
    case GET_COMPANY_MARKET_STAGE_REGION_LOOKUP:
      return {
        ...state,
        companyMarketStageRegionLookUp: action.payload,
        loading: false
      };
    case GET_ROLE_STATUS_LOOKUP:
      return {
        ...state,
        roleStatusLookUp: action.payload,
        loading: false
      };
    case GET_CITY_LOOKUP:
      return {
        ...state,
        cityLookUp: action.payload,
        loading: false
      };
    case GET_STATE_LOOKUP:
      return {
        ...state,
        stateLookUp: action.payload,
        loading: false
      };

    case GET_REGION_LOOKUP:
      return {
        ...state,
        regionLookUpData: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
