import React from 'react'
import '../../css/dashboard.css';
import { connect } from "react-redux";
import { ComboBox, DropDownList, AutoComplete } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import {
    getTitleLookUpData,
    getFunctionLookUpData,
    getCompanyLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getStageLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData,
    getCompanyMarketStageRegionLookUp,
    getAwsLambdaCompanyLookUpData


} from "../../actions/lookupAction";
import { inputValueAction, offerDashPercentageHiddenAction, offerDashAddMemeberIconAction } from "../../actions/dashboard";
import { fnEstimateDashboard, fnComparisonCompensationEstimateDashboard, offerDashRoleInsertFlagAction } from "../../actions/dashboard";
import constValues from './dashboard.json';
import { fnGetNewHires } from "../../actions/roleActions";
import { FnInsertRole } from "../../actions/roleActions";
import {
    getHRDetails
} from "../dropdown/lookups";
import { titlesDataSort, functionalityDataSort, companyDataSort } from "../dropdown/lookupSortingData";
class DashBoardInputs extends React.Component {
    constructor(props) {
        super(props);
        this.props.getTitleLookUpData();
        this.props.getFunctionLookUpData();
        this.props.getgraphAPIListMarketSpaceLookUpData();
        this.props.getCompanyLocationRegionLookUpData();
        this.props.getcompanyLocationNoOfEmployeesLookUpData();
        this.props.getStageLookUpData();
        this.props.getCompanyMarketStageRegionLookUp();
        this.props.getAwsLambdaCompanyLookUpData();
        this.state = {
            Title: "",
            Function: "",
            role_years: this.props.value.role_years,
            base_percentile: "75", // this.props.value.base_percentile,
            comparison_benchmark_percentile: "75",
            isBasePercentileFieldVisible: true,
            pick_benchMark: "",
            Company: this.props.value.Company,
            MarketSpace: this.props.value.MarketSpace,
            Region: this.props.value.Region,
            Stage: this.props.value.Stage,
            comparison_benchmark_hard_code_value: "50th Percentile",
            company_region_lookup_data: []
        };
        this.props.offerDashPercentageHiddenAction(this.state.Stage)
    }
    componentDidMount() {
        if (this.props.location.search !== "") {
            fnGetNewHires(this.props.location.search.slice(9)).then(response => {
                this.state.Company = response.data.response.role_company
                this.state.MarketSpace = response.data.response.role_market_space
                this.state.Region = response.data.response.role_region
                this.state.Stage = response.data.response.role_stage
                this.state.Title = response.data.response.role_title
                this.state.Function = response.data.response.role_function
                if (response.data) {
                    this.setState({
                        Company: response.data.response.role_company,
                        Stage: response.data.response.role_stage,
                        Region: response.data.response.role_region,
                        MarketSpace: response.data.response.role_market_space,
                        // Title: response.data.response.role_title,
                        Function: response.data.response.role_function,
                        marketSpaceLookupData: this.props.lookups.marketSpaceLookUp.data,
                        regionLookupData: this.state.company_region_lookup_data,
                        stageLookupData: this.props.lookups.stageLookUp.data

                    })

                }
                const userJobIdDashBoardData = {
                    job_id: this.props.user_id.user_id,
                    Title: response.data.response.role_title,
                    Function: response.data.response.role_function,
                    role_years: this.props.value.role_years,
                    base_percentile: (this.state.base_percentile / 100).toString(),
                    MarketSpace: response.data.response.role_market_space,
                    Company: response.data.response.role_company,
                    Region: response.data.response.role_region,
                    Stage: response.data.response.role_stage
                }
                this.props.value.Title = response.data.response.role_title
                this.props.value.Function = response.data.response.role_function
                this.props.offerDashPercentageHiddenAction(response.data.response.role_stage)
                this.props.fnComparisonCompensationEstimateDashboard(userJobIdDashBoardData)
            }).catch(error => console.log(error));
        } else {
            getHRDetails(this.props.auth.user._id).then(response => {
                let userDashBoardData = {}

                if (response.data.user.overall_experience !== undefined) {
                    if (response.data.user.overall_experience.title_level === "") {
                        this.state.Company = response.data.msg.company_name
                        this.state.MarketSpace = response.data.msg.company_market_space
                        this.state.Region = response.data.msg.role_region
                        this.state.Stage = response.data.msg.company_stage
                        userDashBoardData = {
                            Title: this.props.value.Title,
                            Function: this.props.value.Function,
                            role_years: this.props.value.role_years,
                            base_percentile: (this.state.base_percentile / 100).toString(),
                            MarketSpace: response.data.msg.company_market_space,
                            Company: response.data.msg.company_name,
                            Region: response.data.msg.role_region,
                            Stage: response.data.msg.company_stage
                        }
                    } else {
                        this.state.Company = response.data.msg.company_name
                        this.state.MarketSpace = response.data.msg.company_market_space
                        this.state.Region = response.data.msg.role_region
                        this.state.Stage = response.data.msg.company_stage
                        userDashBoardData = {
                            Title: response.data.user.overall_experience.title_level,
                            Function: response.data.user.overall_experience.job_function,
                            role_years: "0-2",
                            base_percentile: (this.state.base_percentile / 100).toString(),
                            MarketSpace: response.data.msg.company_market_space,
                            Company: response.data.msg.company_name,
                            Region: response.data.msg.role_region,
                            Stage: response.data.msg.company_stage
                        }
                    }
                    this.props.value.Title = response.data.user.overall_experience.title_level
                    this.props.value.Function = response.data.user.overall_experience.job_function
                    this.props.offerDashPercentageHiddenAction(this.state.Stage)
                    this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData)
                } else {
                    this.state.Company = response.data.msg.company_name
                    this.state.MarketSpace = response.data.msg.company_market_space
                    this.state.Region = response.data.msg.role_region
                    this.state.Stage = response.data.msg.company_stage
                    this.state.marketSpaceLookupData = this.props.lookups.marketSpaceLookUp.data
                    this.state.regionLookupData = this.state.company_region_lookup_data
                    let userDashBoardData2 = {
                        Title: this.state.Title,
                        Function: this.props.value.Function,
                        role_years: this.props.value.role_years,
                        base_percentile: (this.state.base_percentile / 100).toString(),
                        MarketSpace: response.data.msg.company_market_space,
                        Company: response.data.msg.company_name,
                        Region: response.data.msg.role_region,
                        Stage: response.data.msg.company_stage
                    }
                    this.props.value.Title = this.state.Title
                    this.props.value.Function = userDashBoardData2.Function
                    this.props.offerDashPercentageHiddenAction(response.data.msg.company_stage)
                    this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData2)
                    // this.props.fnEstimateDashboard(userDashBoardData2);
                }

                if (response.data) {
                    this.setState({
                        Company: response.data.msg.company_name,
                        Stage: response.data.msg.company_stage,
                        Region: response.data.msg.role_region,
                        MarketSpace: response.data.msg.company_market_space,
                        Title: this.props.value.Title,
                        Function: response.data.response.role_function
                    })
                }

            }).catch(error => console.log(error));
        }

    }
    onChangeLineGraphData = e => {
        const value = e.target.value;
        this.state[e.target.name] = value;
        if (e.target.name === "comparison_benchmark_hard_code_value") {

            this.props.inputValueAction(e.target.name, 75);
            if (e.target.value === "75th Percentile") {
                this.state.comparison_benchmark_percentile = 75
                this.props.estimateDashboard.value = "75"
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your company rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {

                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
            if (e.target.value === "60th Percentile") {
                this.state.comparison_benchmark_percentile = 60
                this.props.estimateDashboard.value = "60"
                this.props.inputValueAction(e.target.name, 60);
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your company rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
            if (e.target.value === "50th Percentile") {
                this.state.comparison_benchmark_percentile = 50
                this.props.inputValueAction(e.target.name, 50);
                this.props.estimateDashboard.value = "50"
                if (this.state.pick_benchMark === "Custom percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Your company rank") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                } else if (this.state.pick_benchMark === "Competition") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "60th percentile") {
                    this.setState({
                        isBasePercentileFieldVisible: false
                    });
                } else if (this.state.pick_benchMark === "") {
                    this.setState({
                        isBasePercentileFieldVisible: true
                    });
                }
            }
        }
        const userDashBoardData = {
            Title: this.state.Title,
            Function: this.state.Function,
            role_years: this.state.role_years,
            base_percentile: (this.state.base_percentile / 100).toString(),
            MarketSpace: this.props.inputValues.MarketSpace,
            Company: this.props.inputValues.Company,
            Region: this.props.inputValues.Region,
            Stage: this.props.inputValues.Stage,
            comparison_benchmark_percentile: this.state.comparison_benchmark_percentile
        }
        this.props.value.Title = this.state.Title
        this.props.value.Function = this.state.Function
        this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData)
    }
    onChange = e => {
        const value = e.target.value;
        this.state[e.target.name] = value;
        this.setState({
            [e.target.name]: value,
            titleLookupData: titlesDataSort(value, this.props.lookups.titleLookUp.data),
            functionalityLookupData: functionalityDataSort(value, this.props.lookups.functionLookUp.data),
            companyLookUpData: companyDataSort(value, this.props.lookups.companyLookUp),
            marketSpaceLookupData: this.props.lookups.marketSpaceLookUp.data,
            regionLookupData: this.state.company_region_lookup_data,
            stageLookupData: this.props.lookups.stageLookUp.data.sort().filter(function (el) {
                return el != "PUBLIC";
            })

        });

        this.props.inputValueAction(e.target.name, e.target.value);


        if (e.target.value === "Custom percentile") {
            this.props.inputValueAction(e.target.name, 50);
            this.state.base_percentile = "50"
            this.setState({
                isBasePercentileFieldVisible: true
            });
        } else if (e.target.value === "Your company rank") {
            this.props.inputValueAction(e.target.name, 0);
            this.state.base_percentile = ""
            this.setState({
                isBasePercentileFieldVisible: true
            });
        } else if (e.target.value === "Competition") {
            this.props.inputValueAction(e.target.name, 80);
            this.state.base_percentile = "80"
            this.setState({
                isBasePercentileFieldVisible: false
            });
        } else if (e.target.value === "60th percentile") {
            this.props.inputValueAction(e.target.name, 60);
            this.state.base_percentile = "60"
            this.setState({
                isBasePercentileFieldVisible: false
            });
        }
        if (e.target.name !== "MarketSpace" && e.target.name !== "role_years") {
            const userDashBoardData = {
                Title: this.state.Title,
                Function: this.state.Function,
                role_years: this.state.role_years,
                base_percentile: (this.state.base_percentile / 100).toString(),
                MarketSpace: this.props.inputValues.MarketSpace,
                Company: this.props.inputValues.Company,
                Region: this.state.Region,
                Stage: this.props.inputValues.Stage
            }
            this.props.value.Title = this.state.Title
            this.props.value.Function = this.state.Function

            this.props.fnComparisonCompensationEstimateDashboard(userDashBoardData)
            // this.props.fnEstimateDashboard(userDashBoardData);
        }
    }


    onInviteMemberClick = () => {
        const userData = {
            user_id: this.props.auth.user._id,
            role_company: this.state.Company,
            role_stage: this.state.Stage,
            role_market_space: this.state.MarketSpace,
            role_region: this.state.Region,
            role_status: "Open",
            role_title: this.state.Title,
            role_function: this.state.Function,
            compensation_total_high: this.props.estimateDashboard.estimateDashboard.data.compensation['total'],
            compensation_base_high: this.props.estimateDashboard.estimateDashboard.data.compensation['base'],
            compensation_bonus_high: this.props.estimateDashboard.estimateDashboard.data.compensation['bonuses'],
            compensation_equity_high: this.props.estimateDashboard.estimateDashboard.data.compensation['equity'],
        }

        this.props.FnInsertRole(userData, this.props.history, "inviteMember");
        this.props.offerDashRoleInsertFlagAction(1)
        this.props.offerDashAddMemeberIconAction(2)
    }

    render() {
        if (this.props.estimateDashboard.name === "pick_benchMark" || this.props.estimateDashboard.name === "donutarrow") {
            this.state.base_percentile = this.props.estimateDashboard.value
        }
        if (this.props.lookups.marketSpaceLookUp.data !== undefined) {
            this.state.marketSpaceLookupData = this.props.lookups.marketSpaceLookUp.data.sort().filter(function (el) {
                return el != "";
            });
        }
        if (this.props.lookups.companyRegionLookUp.length !== undefined) {
            const company_region_lookup_data = this.props.lookups.companyRegionLookUp.sort().filter(function (el) {
                return el != "";
            });
            company_region_lookup_data.map(regionLookupUniqueData => {
                if (this.state.company_region_lookup_data.indexOf(regionLookupUniqueData) === -1) {
                    this.state.company_region_lookup_data.push(regionLookupUniqueData)
                }
            });
        }

        return (
            <div>
                <div className="row" style={{
                    paddingTop: "0px", marginTop: "10px", paddingLeft: "5px",
                    paddingBottom: "0px", marginBottom: "-35px"
                }}>
                    <div className="col-md-8  col-sm-6 col-xs-6 col-lg-8 paddingRight" style={{ fontSize: "18px" }} >

                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-6 col-lg-4 paddingRight">
                        <div className="float-right power_user_dashboard-icon-save" onClick={this.onInviteMemberClick} style={{ cursor: "pointer" }}>
                            <i title="Save Offer" className="fa fa-floppy-o" aria-hidden="true"
                                style={{
                                    margin: "auto", color: "rgba(255,255,255,0.87)", fontSize: "24px", lineHeight: "1", marginLeft: "10px", marginTop: "6px"
                                }}
                            >
                            </i>
                        </div>
                    </div>
                </div>
                <div className="row dashboard-input-wrapper" style={{
                    paddingTop: "0px", marginTop: "30px", paddingLeft: "5px"
                }}>


                    <div className="col-md-3 paddingRight" >
                        <AutoComplete
                            name="Company"
                            onChange={this.onChange}
                            // data={this.state.companyLookUpData}
                            label="Company"
                            value={this.state.Company}
                            // listNoDataRender={this.listNoDataRender}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                            disabled
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <DropDownList
                            name="MarketSpace"
                            onChange={this.onChange}
                            data={this.state.marketSpaceLookupData}
                            label="Market Space"
                            value={this.state.MarketSpace}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <ComboBox
                            name="Stage"
                            onChange={this.onChange}
                            // data={this.state.stageLookupData}
                            label="Stage"
                            // disabled={this.state.disabled}
                            value={this.state.Stage}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                            disabled
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <ComboBox
                            name="Region"
                            onChange={this.onChange}
                            data={this.state.regionLookupData}
                            label="Location"
                            value={this.state.Region}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />

                    </div>
                </div>


                <div className="row dashboard-input-wrapper" style={{ paddingLeft: "5px" }}>
                    <div className="col-md-3 paddingRight">
                        <AutoComplete
                            name="Title"
                            onChange={this.onChange}
                            data={this.state.titleLookupData}
                            label="Title/Level"
                            value={this.state.Title}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <AutoComplete
                            name="Function"
                            onChange={this.onChange}
                            data={this.state.functionalityLookupData}
                            label="Function"
                            value={this.state.Function}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />

                    </div>
                    <div className="col-md-3 paddingRight">
                        <ComboBox
                            name="role_years"
                            onChange={this.onChange}
                            data={constValues.roleYears}
                            value={this.state.role_years}
                            label="Role Yrs"
                            style={{ width: '100%' }}
                        />

                    </div>
                    <div className="col-md-3 paddingRight">
                        <DropDownList
                            name="pick_benchMark"
                            data={constValues.benchMark}
                            label="Pick Benchmark"
                            onChange={this.onChange}
                            value={this.state.pick_benchMark}
                            style={{ width: '100%' }

                            }
                        />
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                    {this.state.isBasePercentileFieldVisible ? <div className="col-md-3 paddingRight" style={{ marginTop: "20px" }}>

                        <Input
                            name="base_percentile"
                            type="text"
                            onChange={this.onChange}
                            label="Custom Percentile"
                            value={this.state.base_percentile}
                            style={{ width: '100%' }}
                            className="inputDotClass"
                        />
                    </div> : false}
                    <br />
                    <div className="row col-md-12" style={{ marginTop: "50px" }}>
                        <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6 paddingRight" style={{ float: "right", textAlign: "right", paddingTop: "28px", fontSize: "16px" }}>Comparison Benchmark: </div>
                        <div className="col-md-2 col-sm-12 col-xs-12 col-lg-2 paddingRight dropdown-label-width" style={{ display: "flex", flexDirection: "row", marginTop: "10px", paddingLeft: "5px" }}>
                            <DropDownList
                                name="comparison_benchmark_hard_code_value"
                                onChange={this.onChangeLineGraphData}
                                data={constValues.comparisonBenchmark}
                                value={this.state.comparison_benchmark_hard_code_value}
                                style={{ width: "120%" }}
                                className="dropdownDotClass2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state =>
    ({
        lookups: state.lookups,
        auth: state.auth,
        role: state.role,
        estimateDashboard: state.estimateDashboard,
    });
export default connect(mapStateToProps, {
    getTitleLookUpData,
    getFunctionLookUpData,
    fnEstimateDashboard,
    inputValueAction,
    FnInsertRole,
    offerDashPercentageHiddenAction,
    offerDashAddMemeberIconAction,
    getCompanyLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getStageLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData,
    getCompanyMarketStageRegionLookUp,
    getAwsLambdaCompanyLookUpData,
    fnComparisonCompensationEstimateDashboard,
    offerDashRoleInsertFlagAction
}
)(DashBoardInputs);
