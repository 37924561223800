import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import '../../css/login.css';
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import RBCarousel from "react-bootstrap-carousel";

class CarouselClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: true
    };
  }
  onSelect = (active, direction) => {
    console.log(`active=${active} && direction=${direction}`);
  };
  render() {

    return (
      <div className="row align-items-center">
        <div className="col-md-2"></div>
        <div className="col-md-4" >
          <Row >
            <Col >
              <RBCarousel
                version={4}
                slideshowSpeed={2000}
                autoplay={this.state.autoplay}
                onSelect={this.onSelect}
              >
                <div >
                  <div className="" >
                    <p className="candidate-slide">
                      <b className="carousel-bold-tag">Candidates </b>
                    </p>
                  </div>
                  <div>
                    <div className="carousel-div-tag-one">
                      <p className="carousel-paragraph-tag-one">
                        <b className="carousel-bold-tag-two">Find your market rank:</b>
                        how does your current compensation rank among your peers?
                      </p>
                    </div>
                    <div className="carousel-div-tag-two">
                      <p className="carousel-paragraph-tag-one">
                        <b className="carousel-bold-tag-two">Rates and recommends your job offers:</b>
                        how strong is your job offer? Can you do better? Apollo rates your offer against your peers and even recommends a more competitive offer.
			                </p>
                    </div>
                    <div className="carousel-div-tag-three">
                      <p className="carousel-paragraph-tag-one">
                        <b className="carousel-bold-tag-two">Explore and test:</b>what would you be paid at other companies? Explore how changing location, market space, company stage and/or level of role affects your pay.
			                </p>
                    </div>
                  </div>
                </div>
                <div >
                  <span className="">
                    <p className="candidate-slide">
                      <b className="carousel-bold-tag-three">Hiring Managers </b>
                    </p>
                    <div className="carousel-div-tag-four" >
                      <p style={{ fontSize: "16px", fontFamily: "Lato" }}>Apollo™ taps pay ranges of<br />
                        <b style={{ fontFamily: "Lato", fontWeight: "700", fontSize: "16px", color: "#60605e", marginLeft: "32px" }}>(1)</b> Our competitors for talent.
                        <br />
                        <b style={{ fontFamily: "Lato", fontWeight: "700", fontSize: "16px", color: "#60605e", marginLeft: "32px" }}>(2)</b>The finalist’s current pay.
                      		<br />
                        <b style={{ fontFamily: "Lato", fontWeight: "700", fontSize: "16px", color: "#60605e", marginLeft: "32px" }}>(3)</b>Averages for the candidate pool itself.
                      		<br />
                      These three unique compensation benchmarks can help us create the most competitive offer possible. Please feel free to submit your own suggested job offer for the position.
                    </p>
                    </div>
                  </span>
                </div>
              </RBCarousel>
            </Col>
          </Row>   
        </div>        
      </div >
    );
  }


}
const Row = props => <div className="row">{props.children}</div>;
  const Col = props => (
    <div className={`col-${props.span}`} style={props.style}>
      {props.children}
    </div>
);        

  CarouselClass.propTypes = {
    auth: PropTypes.object.isRequired
}; 

const mapStateToProps = state =>
  ({
    auth: state.auth,
    errors: state.errors
  });
export default connect(mapStateToProps)(CarouselClass);