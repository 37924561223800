import React, { Component } from 'react';
import logo from "../../img/menu-logo-white.png";
import { fnforgetPassword } from "../../actions/authAction";
import { Link } from "react-router-dom";
import { Input } from '@progress/kendo-react-inputs';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import '../../css/login.css';
import FormValidator from '../../validation/FormValidator';
import validationParams from '../../validation/validationParams.json';

class ForgotPasswordClass extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.forgotPasswordValidations);
    this.state = {
      email: "",
      validation: this.validator.valid()
    };
    this.onChange = this.onChange.bind(this);
    this.forgotPasswordSubmit = this.forgotPasswordSubmit.bind(this);
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.auth.userName = ""
  };


  forgotPasswordSubmit = (event) => {
    event.preventDefault();
    const userData = {
      email: this.state.email
    };
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      fnforgetPassword(userData).then(response => { 
        alert(response.data.message);
      }).catch(err => console.log(err));
    }

  }


  render() {
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation

    let copySuccess = null;
    if (this.props.auth.userName !== "") {
      copySuccess = (
        <p className="heading" style={{ paddingLeft: "60px", color: "red" }}>{this.props.auth.userName}</p>
      );

    }
    return (
      <div className="container-fluid landing">
        <div className="row">
          <div className="col-sm-9 col-md-9 col-xs-9 col-lg-9">
            {/* code for carousel */}
          </div>
          <div className="col-sm-3 col-md-3 col-xs-3 col-lg-3" 
               style={{backgroundColor:"rgba(255,255,255,.85)"}}>
            
              <img
                ref={(image) => this.image = image}
                src={logo}
                alt="Empty"
                style={{
                  backgroundColor: "#3A4158",
                  marginBottom: "51px",
                  width: "90%",
                  margin: "40px 0px 15px 20px"
                }}
              />
            
            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-6">
                <div className="">
                  <div className="card-block">
                    <form className="k-form" onSubmit=                         {this.forgotPasswordSubmit} >
                      <fieldset >
                        <span style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: "#3c3b3b",
                          marginBottom: "-10px",
                          marginLeft: "-30px"
                        }}>Forgot Password:</span>
                        <div className="mb-3 input-wrapper-envelope" 
                             style={{marginLeft: "-10%"}}>
                          <Input
                            name="email"
                            type="email"
                            style={{
                              borderStyle: "none",
                              paddingLeft: "40px",
                              borderRadius: "5px",
                              marginTop: "10px",
                              width: "120%",
                              height: "35px",
                              backgroundColor: "rgb(255, 255, 255)"
                            }}
                            onChange={this.onChange}
                            placeholder="Email address"

                          />
                          <div style={{
                            color: "red",
                            paddingLeft: "60px"
                          }}>
                            {validation.email.message}
                          </div>

                        </div>
                        <br />
                      
                      {copySuccess}
                      </fieldset>

                      <div style={{ marginTop: "-60px", 
                                    marginLeft: "-10%" 
                                 }}>
                        <input type="submit" 
                              className="react-new-login-btn angular-new-login-btn-primary" 
                              style={{ width: "110%" }} 
                              value="Recover Password" />
                      </div>
                                          
                      <div> 
                        <p className="paragraph-account" 
                          style={{ color: "#777", 
                                  fontWeight: "lighter", 
                                  marginTop:"20px" 
                                }}> 
                                Need an account?
                                <Link style={{ color: "#22aae4",                            textDecoration: "none",                      fontWeight: "500",
                                               marginLeft: "5px"
                                             }} 
                                to="/register">   
                                Register here</Link>
                        </p>
                      </div>

                      <p className="text-muted" 
                         style={{marginTop:"80px", marginLeft:"-20px"}}> 
                         Apollo is committed to securing and protecting your information. Find our privacy policy 
                         <Link to="/register" 
                               style={{ color: "#22aae4",
                                        marginLeft: "5px" 
                                     }}> 
                               here.
                        </Link>
                      </p>
                      <p className="text-muted" 
                         style={{marginBottom: "70px", marginLeft:"-20px"}}> 
                         © Apollo, 2019. 
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div >
          </div>
        </div>
      </div>
    );
  }
}

ForgotPasswordClass.propTypes = {
  fnforgetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    errors: state.errors
  });
  
export default connect(mapStateToProps, { fnforgetPassword }
)(ForgotPasswordClass);