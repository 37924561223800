import React from 'react'
import '../../../css/dashboard.css';
import initialStateParams from '../../../validation/validationParams.json';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DashPowerUserBoardInputs from '../powerUserDashboard/DashPowerUserBoardInputs';
import LineChartComponent from '../LineChart';
import OfferLineChartComponent from '../../dashboard/companyDashboard/offerdashboard/OfferdashboardLineChart'

import RightSide from '../RightSideDashboard';
import OfferRightSide from '../companyDashboard/offerdashboard/OfferRightSideDashboard';
import OfferDashboardInputs from '../companyDashboard/offerdashboard/offerDashboardInputs'
import OfferDashboardCandidateGrid from '../companyDashboard/offerdashboard/offerDashboardCandidateGrid'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import {
    getHRDetails
} from "../../dropdown/lookups";
import { offerDashAddMemeberIconAction } from "../../../actions/dashboard";
export class PopwerUserDashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialStateParams.estimateDashBoard

    }
    handleSelect = (e) => {
        this.setState({ selected: e.selected })
        this.props.offerDashAddMemeberIconAction(e.selected)
    }

    componentDidMount() {
        getHRDetails(this.props.auth.user._id).then(response => {
            if (response.data) {
                this.setState({
                    Company: response.data.msg.company_name,
                    Stage: response.data.msg.company_stage,
                    Region: response.data.msg.role_region,
                    MarketSpace: response.data.msg.company_market_space
                })
            }

        }).catch(error => console.log(error));
    }
    render() {
        var inputValues = {
            Company: this.state.Company,
            Stage: this.state.Stage,
            Region: this.state.Region,
            MarketSpace: this.state.MarketSpace
        }
        var location = this.props.location
        var history = this.props.history
        if (this.props.estimateDashboard.addMemberInviteButton === 1) {
            this.state.selected = 1
        }
        if (this.props.estimateDashboard.addMemberInviteButton === 0) {
            this.state.selected = 0
        }
        return (
            <TabStrip selected={this.state.selected} onSelect={this.handleSelect} className="col-sm-12 col-xs-12 col-md-12 col-lg-12 Tabstripcontent" style={{ margin: "2px", marginLeft: "0px", paddingLeft: "80px", marginTop: "25px" }
            }>
                <TabStripTab admin
                    title="POWER-USER" className="col-sm-12 col-xs-12 col-md-12 col-lg-12 power-user-tabstrip-Tab">
                    <div className="power-user-dashboard-container" style={{ paddingLeft: "16px", paddingTop: "0px", margin: "0px", paddingRight: "0px" }}>
                        <div className="row power-user-dashboard-row" >
                            <div className="col-md-9">
                                <DashPowerUserBoardInputs value={initialStateParams.estimateDashBoard} inputValues={inputValues} history={history} />
                                <LineChartComponent value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                            </div>
                            <div className="col-md-3 dashboard-right">
                                <RightSide value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                            </div>
                        </div>
                    </div>
                </TabStripTab>
                <TabStripTab title="CREATE-OFFER">
                    <div className="" style={{ padding: "0px", paddingTop: "0px" }}>
                        <div className="row" style={{ border: "1px solid #d9d9d9", margin: "0px" }}>
                            <div className="col-md-9 dashboard-left">
                                <OfferDashboardInputs value={initialStateParams.estimateDashBoard} inputValues={inputValues} location={location} />
                                <OfferLineChartComponent value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                            </div>
                            <div className="col-md-3 dashboard-right" style={{ border: "1px solid red" }}>
                                <OfferRightSide value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                            </div>
                            <div className="col-md-12"><OfferDashboardCandidateGrid /></div>
                        </div>
                    </div>

                </TabStripTab>
                <TabStripTab title="CANDIDATE">
                    <p>Tab 3 Content</p>
                </TabStripTab>
                <TabStripTab title="MY OFFERS">
                    <p>Tab 4 Content</p>
                </TabStripTab>
            </TabStrip >
        )
    }
}

PopwerUserDashBoard.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state =>
    ({
        auth: state.auth,
        errors: state.errors,
        estimateDashboard: state.estimateDashboard,
        lookups: state.lookups
    });
export default connect(mapStateToProps, { offerDashAddMemeberIconAction }
)(PopwerUserDashBoard);
