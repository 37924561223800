import React from 'react'
import '../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction } from "../../../actions/dashboard";
export class OfferRightSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base_percentile: "75",
      selected: 0,
      compensationBase: "",
      compensationBonuses: "",
      baselock: false,
      bonuslock: false,
      equitylock: true,
      compensationEquity: 0,
      isDisabledBase: false,
      isDisabledBonus: false,
      isDisabledEquity: false,
      compensationPercentBase: "",
      compensationPercentBonuses: "",
      compensationPercentEquity: "",
      compensationBase: "",
      compensationBonuses: "",
      compensationEquity: "",
      compensationTotalValue: "",
    }

  }




  render() {

    return (
      <div className="">

      </div >
    )
  }
}



const mapStateToProps = state =>
  ({
    estimateDashboard: state.estimateDashboard,
  });
export default connect(mapStateToProps, { inputValueAction }
)(OfferRightSide);
