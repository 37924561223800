import React from 'react'
import '../../../css/dashboard.css';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import constValues from '../dashboard.json';
class FixedDashboardHead extends React.Component {
    render() {

        return (
            <React.Fragment>
                <div>
                    <div className="row ">
                        <div className="col-md-9">

                        </div>
                        <div className="col-md-3" style={{ marginTop: "8px" }}>
                            <div className="dashboard-icon-save">
                                <i title="Save Offer" className="fa fa-floppy-o" aria-hidden="true"
                                    style={{
                                        margin: "auto", color: "rgba(255,255,255,0.87)", fontSize: "24px", lineHeight: "1", marginLeft: "10px", marginTop: "6px"
                                    }}>
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="row fixed-rateMyOffer-dashboards" style={{ paddingTop: "0px", marginTop: "30px" }}>
                        <div className="col-md-3">
                            <div className="rate-offer-div-box" style={{ backgroundColor: "#3b4156" }}>
                                <div>OFFER</div>
                                <div style={{ fontSize: "25px" }}>
                                    <small style={{ fontSize: "14px" }}>$</small> 0
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="rate-offer-div-box" style={{ backgroundColor: "#81878D" }}>
                                <div>YOU</div>
                                <div style={{ fontSize: "25px" }}>
                                    <small style={{ fontSize: "14px" }}>$</small> 500,000
                                        </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <DropDownList
                                        data={constValues.recomendation}
                                        defaultValue="PEOPLE LIKE ME"
                                        style={{ width: "100%", fontSize: "12PX", borderStyle: "none" }}
                                    />
                                </div>
                                <div style={{ fontSize: "25px" }}>
                                    <small style={{ fontSize: "14px" }}>$</small> 0
                                    </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div style={{ fontSize: "14px", padding: "10px" }}>
                                <div>RATE</div>
                                <div style={{ fontSize: "25px" }}>
                                    90%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default FixedDashboardHead;
