import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Link } from "react-router-dom";
import "../../../css/MyOffergrid.css"

export default function MyRoleButtons(editField) {

  return class extends GridCell {

    render() {
      let id = this.props.dataItem._id;
      let companyOfferId = this.props.dataItem.offers._id;

      const DESIRE_OFFER_ID = "/home/add-offer/"+id+"/"+companyOfferId;
      const OFFER_ACCEPT = "/home/offer-accept/"+id+"/"+companyOfferId;
      const OFFER_REJECT = "/home/offer-reject/"+id+"/"+companyOfferId;

      return !this.props.dataItem[editField]
        ? (
          <td>
            <Link type="button" to={DESIRE_OFFER_ID} title="Add Offer" className="k-button" >
              <span className="add-offer-icon fa fa-handshake-o"></span>
            </Link>

            <Link type="button" to="/home/my-offer-dashboard" title="Explore Dashboard" className="k-button" >
              <span className="k-icon k-i-group .k-i-group"></span>
            </Link>

            <Link type="button" to={OFFER_ACCEPT} title="Accept" className="k-button" >
              <span className="k-icon k-i-check .k-i-checkmark"></span>
            </Link>

            <Link type="button" to={OFFER_REJECT} title="Reject" className="k-button" >
              <span className="k-icon k-i-close .k-i-x"></span>
            </Link>


          </td>
        )
        : null;
    }
  }
};
