import axios from "axios";
import { SET_CURRENT_ROLES, GET_ROLES, CLEAR_ERRORS } from "./types";


export const FnInsertRole = (userData, history, data) => dispatch => {
  dispatch(clearErrors());

  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-new-hire-post", userData)
    .then(res => {
      dispatch(setCurrentUserRole(res));
      if (data === "inviteMember") {
        history.push("/home/estimate-dashboard?user_id=" + res.data.response.upserted['0']['_id'])
      } else {
        history.push("/home/role")
      }
      if (res.err) {
        console.log(res.err)
      }


    })
    .catch(err => {
      console.log(err)
    }
    );
};
export const fnGetAllNewHires = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-get-grid?id=" + id)
    .then(res => {
      const RolesListData = res.data.response;
      const RolesList = []
      for (let i = 0; i < RolesListData.length; i++) {
        RolesList.push(RolesListData[i])
      }
      dispatch(setGetAllRole(RolesList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export const fnGetUpdateHires = (userData, history) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-new-hire-put", userData)
    .then(res => {
      dispatch(setCurrentUserRole(res));
      history.push("/home/role")
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export function fnGetNewHires(id) {
  return axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-get-form?id=" + id)
}

export function fnDeleteHires(data) {
  return axios
    .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-new-hire-del?id=" + data)
}
//UserProfile
export const setCurrentUserRole = (users) => {
  return {
    type: SET_CURRENT_ROLES,
    payload: users
  };
};

//GetAllProfile
export const setGetAllRole = (users) => {
  return {
    type: GET_ROLES,
    payload: users
  };
};
// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};