import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction, offerDashDialogBoxAction, fnAddMemberPowerUserDashboard } from "../../../../actions/dashboard";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import FormValidator from '../../../../validation/FormValidator';
import validationParams from '../../../../validation/validationParams.json';
import { FnInsertHiringTeamMembers } from "../../../../actions/roleHiringTeamMemberAction"
export class offerDashbBoardAddHiringTeamMember extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.offerDashBoardInviteValidations);
    this.state = {
      visible: true,
      first_name: "",
      last_name: "",
      email: "",
      validation: this.validator.valid()
    }
    this.submitted = false;
    this.InvitePopupSubmit = this.InvitePopupSubmit.bind(this);
  }
  onChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    })
  }
  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible,
      first_name: "",
      last_name: "",
      email: ""
    });
    this.props.offerDashDialogBoxAction(this.state.visible, false)
  }
  toggleDialogStable = () => {
    this.setState({
      visible: this.state.visible
    });
    console.log("hello")
  }
  InvitePopupSubmit = (event) => {
    event.preventDefault();
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      const userData = {
        hr_id: this.props.auth.user._id,
        new_hire_id: this.props.location.search.slice(9),
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        company_name: this.props.company,
      };
      this.props.FnInsertHiringTeamMembers(userData, "offer_dashboard_invite", this.props.location.search.slice(9))
      this.props.estimateDashboard.togglename = ""
      this.state.first_name = ""
      this.state.last_name = ""
      this.state.email = ""

    }
  }

  render() {

    if (this.props.estimateDashboard.togglename === "add_hiring_team_member") {
      this.state.visible = true
    } else {
      this.state.visible = false
    }
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation

    return (
      <div>
        {this.state.visible && <Dialog className="offer-save-div-modal">
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 offer-save-dialog-div" onClick={this.toggleDialogStable}>
            <div className="col-md-11 col-lg-11 col-sm-8 col-xs-8">
              Add Hiring Team Member
            </div>
            <div className="col-md-1 col-lg-1 col-sm-4 col-xs-4" style={{ textAlign: "right" }}>
              <button className="offer-dash-offer-save-dialog-button" onClick={this.toggleDialog}>X</button>
            </div>
          </div>
          <form action="" >
            <div className="offer-save-dialog-input-div">

              <div className="col-md-12 col-sm-11 col-xs-11 col-lg-12 offer-save-input-field">
                <Input
                  name="first_name"
                  type="text"
                  style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  onChange={this.onChange}
                  label="First Name *"
                  value={this.state.first_name}

                />
                <div style={{ color: "red", paddingLeft: "0px" }}>
                  {validation.first_name.message}
                </div>
              </div>

              <div className="col-md-12 col-sm-11 col-xs-11 col-lg-12 offer-save-input-field" style={{ marginTop: "10px" }}>
                <Input
                  name="last_name"
                  type="text"
                  style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  onChange={this.onChange}
                  label="Last Name *"
                  value={this.state.last_name}
                />
                <div style={{ color: "red", paddingLeft: "0px" }}>
                  {validation.last_name.message}
                </div>
              </div>
              <div className="col-md-12 col-sm-11 col-xs-11 col-lg-12 offer-save-input-field" style={{ marginTop: "10px" }}>
                <Input
                  name="email"
                  type="email"
                  style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  onChange={this.onChange}
                  label="Email *"
                  value={this.state.email}
                />
                <div style={{ color: "red", paddingLeft: "0px" }}>
                  {validation.email.message}
                </div>
              </div>
            </div>

            <div className="offer-save-buttons-div">
              <DialogActionsBar >
                <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog}>CANCEL</button>
                <button type="submit" className="k-button col-xs-6 col-sm-6" onClick={this.InvitePopupSubmit}>SUBMIT</button>
              </DialogActionsBar>
            </div>
          </form>
        </Dialog>}
      </div >

    )
  }
}
const mapStateToProps = state =>
  ({
    estimateDashboard: state.estimateDashboard,
    auth: state.auth
  });
export default connect(mapStateToProps, { inputValueAction, offerDashDialogBoxAction, fnAddMemberPowerUserDashboard, FnInsertHiringTeamMembers }
)(offerDashbBoardAddHiringTeamMember);
