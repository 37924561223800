import React from 'react'
import { Link } from 'react-router-dom';
import logo from "../../img/menu-logo-white.png";
export class Landing extends React.Component {
    render() {
        return (
            <div className="landing">
                <div className="landing-header-wrapper">
                    <Link to="/"><img src={logo} alt="Empty" className="landing-logo" /></Link>
                    <Link to="/login" className="landing-login">Login</Link>
                    <Link to="/register" className="landing-signup">Sign Up</Link>
                </div>
                <p className='header'>Get Competitive: negotiate smart job offers with real-time data in real markets.</p>
            </div>
        )
    }
}
