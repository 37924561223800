import React from 'react'
import '../../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';

import { inputValueAction, offerDashDialogBoxAction } from "../../../../actions/dashboard";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import {
  getStageLookUpData,
  getgraphAPIListMarketSpaceLookUpData, getcompanyLocationNoOfEmployeesLookUpData,
  getTitleLookUpData,
  getFunctionLookUpData,
  getCompanyLocationRegionLookUpData,
  getCompanyRelatedData
} from "../../../../actions/lookupAction"
import {
  titlesDataSort,
  functionalityDataSort,
  regionDataSort,
  marketSpaceDataSort,
  employeesDataSort,
  companyForEmployessDataSort
} from "../../../dropdown/lookupSortingData";
import PropTypes from "prop-types";
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import {
  FnInsertRoleCandidates,
} from "../../../../actions/roleCandidateAction"
import FormValidator from '../../../../validation/FormValidator'
import validationParams from '../../../../validation/validationParams.json'

export class OfferDashCandidateAddButton extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.OfferformValidations);
    this.state = {
      visible: true,
      selected: 0,

      success: false,
      first_name: "",
      last_name: "",
      email: "",
      message: "",
      base_salary: null,
      annual_bonus: null,
      commission: null,
      equity_percent: null,
      equity_dollar_value: null,
      total_dollar_value: null,
      candidate_accepted: "",
      company: "",
      stage: "",
      region: "",
      company_revenue: "",
      market_space: "",
      employees_count: "",
      role_id: "",
      validation: this.validator.valid()
    }
    this.CandidateFormSubmit = this.CandidateFormSubmit.bind(this);
  }
  componentDidMount() {
    this.props.getStageLookUpData();
    this.props.getgraphAPIListMarketSpaceLookUpData();
    this.props.getcompanyLocationNoOfEmployeesLookUpData();
    this.props.getTitleLookUpData();
    this.props.getFunctionLookUpData();
    this.props.getCompanyLocationRegionLookUpData();
    this.props.getCompanyRelatedData();
    this.state.role_id = window.location.hash.split('?user_id=')[1]
    this.setState({
      stageLookupData1: (this.props.lookups.stageLookUp.data),

    });
  }
  onChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
      stageLookupData1: (this.props.lookups.stageLookUp.data),
      marketSpaceLookupData: marketSpaceDataSort(value, this.props.lookups.marketSpaceLookUp.data),
      companyForEmployesLookupData: companyForEmployessDataSort(value, this.props.lookups.companyForEmployeesLookUp),
      titleLookupData: titlesDataSort(value, this.props.lookups.titleLookUp.data),
      functionalityLookupData: functionalityDataSort(value, this.props.lookups.functionLookUp.data),
      employeeLookupData: employeesDataSort(value, this.props.lookups.noOfEmployeesLookUp),
      regionLookupData: regionDataSort(value,
        this.props.lookups.companyRegionLookUp)
    });

    if (e.target.name === "company") {
      const companyRelatedDataLookup = this.props.lookups.companyRelatedData;
      for (let i = 0; i < companyRelatedDataLookup.length; i++) {
        if (companyRelatedDataLookup[i]["company"] === e.target.value) {
          this.setState({
            company: companyRelatedDataLookup[i]["company"],
            market_space: companyRelatedDataLookup[i]["marketspace"],
            region: companyRelatedDataLookup[i]["region"],
            employees_count: companyRelatedDataLookup[i]["employees"],
            stage: companyRelatedDataLookup[i]["stage"]
          });
          this.state.company_competitors01 = companyRelatedDataLookup[i].Competitors01
        }
      }
    }

  }


  handleSelect = (e) => {
    this.setState({ selected: e.selected })
  }
  buttonClicked = (text) => {

    this.setState({ selected: this.state.selected + 1 });
    if (text === "selected-zero") {
      this.setState({
        selected: 0
      })
    }
  }

  CandidateFormSubmit = (event) => {
    event.preventDefault();

    const userData = {
      role_from_dashboard: true,
      hr_id: this.props.auth.user._id,
      new_hire_id: this.state.role_id,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      location_state: this.state.location_state,
      location_city: this.state.location_city,
      experience: {
        company: this.state.company,
        market_space: this.state.market_space,
        stage: this.state.stage,
        employees_count: this.state.employees_count,
        region: this.state.region
      },
      overall_experience: {
        title_level: this.state.title_level,
        job_function: this.state.job_function,
      },
      salary: {
        base_salary: this.state.base_salary,
        annual_bonus: this.state.annual_bonus,
        commission: this.state.commission,
        equity_percent: this.state.equity_percent,
        equity_dollar_value: this.state.equity_dollar_value,
        total_dollar_value: this.state.total_dollar_value,
        candidate_accepted: this.state.candidate_accepted,
        equity_type_rsu: this.state.equity_type_rsu,
        equity_type_stock: this.state.equity_type_stock,
      }
    };

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      this.props.FnInsertRoleCandidates(userData, "history", this.state.role_id)
      this.props.history.push(this.props.location.pathname + "?user_id=" + this.state.role_id)
    }
  }
  render() {
    if (this.props.estimateDashboard.togglename === "floppy_icon") {
      this.state.visible = true
    } else {
      this.state.visible = false
    }
    this.state.total_dollar_value = this.state.base_salary + this.state.annual_bonus + this.state.commission + this.state.equity_dollar_value

    return (
      <div>
        <div class="container mt-3" style={{ width: "100%" }}>
          <div class="modal fade" id="myModal">
            <div class="modal-dialog modal-lg" style={{ left: "60px" }}>
              <div class="modal-content" style={{ width: "100%" }}>
                <div class="modal-header">
                  <h2 style={{ fontSize: "22px", color: "#666666", fontWeight: "300" }}>Add Candidate</h2>
                  <button type="button" class="close" data-dismiss="modal" onClick={() => { this.buttonClicked("selected-zero") }}>×</button>
                </div>
                <form onSubmit={this.CandidateFormSubmit}>
                  <TabStrip className="popup-tabstrip" selected={this.state.selected} onSelect={this.handleSelect}>
                    <TabStripTab title="BASIC DETAILS">
                      <div style={{ marginTop: "10px", marginLeft: "2%", width: '780px' }} className="tabstrip-label-font1">

                        <Input
                          name="first_name"
                          type="text"
                          style={{ width: '100%' }}
                          onChange={this.onChange}
                          label="First Name *"
                          required="true"
                        />
                      </div>
                      <div style={{ marginTop: "15px", marginLeft: "2%", width: '780px' }} className="tabstrip-label-font1">

                        <Input
                          name="last_name"
                          type="text"
                          style={{ width: '100%' }}
                          onChange={this.onChange}
                          label="Last Name *"
                          required="true"
                        />
                      </div>
                      <div style={{ marginTop: "15px", marginLeft: "2%", width: '780px' }} className="tabstrip-label-font1">

                        <Input
                          name="email"
                          type="text"
                          style={{ width: '100%' }}
                          onChange={this.onChange}
                          label="Email *"
                          required="true"
                        />
                      </div>
                      <div style={{ marginTop: "15px", marginLeft: "2%", width: '780px' }} className="tabstrip-label-font">
                        <AutoComplete
                          name="company"
                          type="text"
                          style={{ width: '100%' }}
                          onChange={this.onChange}
                          label="Company"
                          data={this.state.companyForEmployesLookupData}
                          value={this.state.company}
                        />
                      </div>
                      <div style={{ marginTop: "15px", marginLeft: "2%", width: '780px' }} className="tabstrip-label-font">
                        <AutoComplete
                          name="title_level"
                          type="text"
                          style={{ width: '100%' }}
                          onChange={this.onChange}
                          label="Title"
                          data={this.state.titleLookupData}
                          value={this.state.title_level}
                        />
                      </div>
                      <div style={{ marginTop: "15px", width: '780px', marginBottom: "30px", marginLeft: "2%" }} className="tabstrip-label-font">
                        <AutoComplete
                          name="job_function"
                          type="text"
                          style={{ width: '100%' }}
                          onChange={this.onChange}
                          label="Function"
                          value={this.state.job_function}
                          data={this.state.functionalityLookupData}
                        />
                      </div>
                      <div class="modal-footer" style={{ marginTop: "5px" }}>
                        <Button type="button" onClick={() => { this.buttonClicked("click1") }}>NEXT</Button>
                      </div>

                    </TabStripTab>

                    <TabStripTab title="COMPANY DATA">
                      <div style={{ marginTop: "10px", width: "780px", marginLeft: "2%", zIndex: "1000" }}  >
                        <AutoComplete
                          name="stage"
                          style={{ width: '99%' }}
                          label="Stage"
                          onChange={this.onChange}
                          data={this.props.lookups.stageLookUp.data}
                          value={this.state.stage}
                        />
                      </div>
                      <div style={{ marginTop: "30px", width: "780px", marginLeft: "2%" }} className="tabstrip-label-font">
                        <AutoComplete style={{ width: '100%' }}
                          name="market_space"
                          label="Market Space"
                          onChange={this.onChange}
                          data={this.state.marketSpaceLookupData}
                          value={this.state.market_space}
                        />
                      </div>
                      <div style={{ marginTop: "30px", marginLeft: "2%", width: '780px' }} >
                        <AutoComplete
                          name="employees_count"
                          style={{ width: '99%' }}
                          label="Size by Employees"
                          onChange={this.onChange}
                          data={this.state.employeeLookupData}
                          value={this.state.employees_count}
                        />
                      </div>
                      <div style={{ marginTop: "30px", width: '780px', marginLeft: "2%" }} >
                        <AutoComplete
                          name="region"
                          style={{ width: '99%' }}
                          label="Location"
                          onChange={this.onChange}
                          data={this.state.regionLookupData}
                          value={this.state.region}
                        />
                      </div>

                      <div class="modal-footer" style={{ marginTop: "5px" }}>
                        <Button type="button" onClick={() => { this.buttonClicked("click2") }}>NEXT</Button>
                      </div>
                    </TabStripTab>
                    <TabStripTab title="COMPENSATION">
                      <div className="tabstrip-numerictextbox-label-font" style={{ marginTop: "10px", width: "800px", marginLeft: "2%" }} >
                        <NumericTextBox
                          name="base_salary"
                          width='96%'
                          label="Base$('000)*"
                          defaultValue="0"
                          required="true"
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="tabstrip-numerictextbox-label-font" style={{ marginTop: "20px", width: "800px", marginLeft: "2%" }} >
                        <NumericTextBox
                          name="annual_bonus"
                          width='96%'
                          label="Bonus$('000)*"
                          defaultValue="0"
                          required="true"
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="tabstrip-numerictextbox-label-font" style={{ marginTop: "20px", width: "800px", marginLeft: "2%" }} >
                        <NumericTextBox
                          name="commission"
                          width='96%'
                          label="Commission$('000)*"
                          defaultValue="0"
                          required="true"
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="tabstrip-numerictextbox-label-font" style={{ marginTop: "20px", width: "800px", marginLeft: "2%" }} >
                        <NumericTextBox
                          name="equity_dollar_value"
                          width='96%'
                          label="Annual Equity$('000)*"
                          defaultValue="0"
                          required="true"
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="tabstrip-numerictextbox-label-font" style={{ marginTop: "20px", width: "800px", marginLeft: "2%" }} >
                        <NumericTextBox
                          name="equity_percent"
                          width='96%'
                          label="Equity %"
                          defaultValue="0"
                          required="true"
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="tabstrip-numerictextbox-label-font" style={{ marginTop: "20px", width: "800px", marginLeft: "2%" }} >
                        <NumericTextBox
                          name="total_dollar_value"
                          width='96%'
                          label="Total$('000)*"
                          disabled="true"
                          defaultValue="0"
                          onChange={this.onChange}
                          value={this.state.total_dollar_value}
                        />
                      </div>



                      <div class="modal-footer" style={{ marginTop: "5px" }}>
                        <Button type="button" data-dismiss="modal" onClick={() => { this.buttonClicked("selected-zero") }}
                        >CANCEL</Button>
                        <Button type="button" data-dismiss="modal" onClick={this.CandidateFormSubmit}
                        >SUBMIT</Button>
                      </div>
                    </TabStripTab>
                  </TabStrip>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div >

    )
  }
}

OfferDashCandidateAddButton.propTypes = {
  auth: PropTypes.object.isRequired,
  FnInsertRoleCandidates: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    estimateDashboard: state.estimateDashboard,
    lookups: state.lookups,
    errors: state.errors,
  });
export default connect(mapStateToProps, {
  companyForEmployessDataSort,
  getcompanyLocationNoOfEmployeesLookUpData, getgraphAPIListMarketSpaceLookUpData,
  getStageLookUpData,
  inputValueAction,
  offerDashDialogBoxAction,
  getTitleLookUpData,
  getFunctionLookUpData,
  getCompanyLocationRegionLookUpData,
  getCompanyRelatedData,
  FnInsertRoleCandidates
}
)(OfferDashCandidateAddButton);
