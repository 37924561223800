import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import "../../../css/MyOffergrid.css"
import MyRoleButtons from '../Candidate/Offergridbutton';
import data from '../Candidate/data.json';
import { fnGetAllCandidateOffers, fnOfferAcceptOrReject } from "../../../actions/candidateOffersAction";

class CustomCell extends React.Component {
  render() {
      const value = this.props.dataItem.offers.status;
      return (
        <td>{value==='Accepted' ? <span style={{color:"green"}}>{value}</span>:      value==='Rejected' ? <span style={{color:"red"}}>{value}</span> :       <span style={{color:"black"}}>{value}</span>}
        </td>
      );
  }
}

class MyOfferGrid extends React.Component {
  RoleButtons;
  constructor(props) {
    super(props);
    this.state = {
      candidateOffersList: [],
      gridData: data,
      success: false,
      skip: 0, take: 10, sort: [
        { field: 'from', dir: 'asc' }
      ],
      from: ""
    };
    this.RoleButtons = MyRoleButtons("inEdit");

  }

  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  }

  componentDidMount() {
    
    if (this.props.match.path === "/home/offer-accept/:id/:companyOfferId" ){
      let offerAcceptData = {
         companyOfferId: this.props.match.params.companyOfferId,
         id: this.props.match.params.id,
         candidate_accepted: "1"
      }
      this.props.fnOfferAcceptOrReject(offerAcceptData, this.props.history);
    } else if (this.props.match.path === "/home/offer-reject/:id/:companyOfferId" ){
      let offerAcceptData = {
        companyOfferId: this.props.match.params.companyOfferId,
        id: this.props.match.params.id,
        candidate_accepted: "0"
     }
     this.props.fnOfferAcceptOrReject(offerAcceptData, this.props.history);
    }
    else {
      if (this.props.auth.user._id !== null) {
        let id = this.props.auth.user._id;
        this.props.fnGetAllCandidateOffers(id);
      }
    }    
  }

  
  MyCustomCell = (props) => <CustomCell {...props} myProp={this.customData} />
  
  render() {
    if (this.props.candidateOffers.candidateOffersData === null) {
      this.state.candidateOffersList = []
    } else {
      this.state.candidateOffersList = this.props.candidateOffers.candidateOffersData;
    }

    return (
      <div className="container-fluid role-main-div">
        <div className="role-main-plus-button"> My Offers
          </div>
        <Grid
          className="role-grid"
          data={orderBy(this.state.candidateOffersList.slice(this.state.skip, this.state.take + this.state.skip), this.state.sort)}
          skip={this.state.skip}
          take={this.state.take}
          total={this.state.candidateOffersList.length}
          pageable={true}
          onPageChange={this.pageChange}
          sortable={true}
          sort={this.state.sort}
          onSortChange={(e) => {
            this.setState({
              sort: e.sort
            });
          }}
        >
          <Column field="offers.offer_from" title="FROM" />
          <Column field="offers.offer_date" format="{0:d}" title="DATE" />
          <Column field="new_hire.role_company" title="COMPANY" />
          <Column field="new_hire.role_title" title="TITLE" />
          <Column field="offers.total_dollar_value" title="TOTAL COMPENSATION" />
          <Column field="offers.status" cell={this.MyCustomCell} title="STATUS" />
          <Column cell={this.RoleButtons} title="ACTION" />
        </Grid>
      </div>
    );
  }
}


MyOfferGrid.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  candidateOffers: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    errors: state.errors,
    candidateOffers: state.candidateOffers
  });
export default connect(mapStateToProps, 
  { fnGetAllCandidateOffers, fnOfferAcceptOrReject }
)(MyOfferGrid);