import {
  SET_CANDIDATE_PROFILE,
  GET_CANDIDATE_PROFILE_LOADING
} from "../actions/types";

const initialState = {
  candidateProfile: null,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CANDIDATE_PROFILE_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_CANDIDATE_PROFILE:
      return {
        ...state,
        candidateProfile: action.payload,
        loading: true
      };
    default:
      return state;
  }
}
