import {
  SET_CURRENT_HIRING_TEAM_MEMBERS,
  HIRING_TEAM_MEMBERS_LOADING,
  SET_INSERT_UPDATE_HIRING_TEAM_MEMBERS,
  GET_HIRING_TEAM_MEMBERS,
} from "../actions/types";

const initialState = {
  hiringMembers: {},
  hiringMembersData: null,
  RoleHrSuccessData: null,
  loading: false
};

export default function (state = initialState, action) {

  switch (action.type) {
    case HIRING_TEAM_MEMBERS_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_CURRENT_HIRING_TEAM_MEMBERS:
      return {
        ...state,
        hiringMembers: action.payload,
        loading: true
      };
    case GET_HIRING_TEAM_MEMBERS:
      return {
        ...state,
        hiringMembersData: action.payload,
        loading: true
      };
    case SET_INSERT_UPDATE_HIRING_TEAM_MEMBERS:
      return {
        ...state,
        RoleHrSuccessData: action.payload,
        loading: true
      };
    default:
      return state;
  }
}
