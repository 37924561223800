import axios from "axios";
import {
  SET_CURRENT_ESTIMATE_DASHBOARD, CLEAR_ERRORS, SET_CURRENT_INPUT_ETIMATE_DASHBOARD, SET_CURRENT_DIALOGBOX_OFFER_DASHBOARD, SET_CURRENT_PERCENTAGE_HIDDEN_OFFER_DASHBOARD, SET_CURRENT_ADD_MEMBER_ICON_OFFER_DASHBOARD, SET_CURRENT_ADD_UPDATE_TMP_POWER_DASHBOARD, SET_CURRENT_ADD_UPDATE_CREATE_OFFER_DASHBOARD, SET_ROLE_INSERT_FLAG_OFFER_DASHBOARD,
  SET_ROLE_VALUES_RIGHT_SIDE_OFFER_DASHBOARD
} from "./types";


// Esstimate comparison data
export const fnComparisonCompensationEstimateDashboard = (userData) => dispatch => {
  if (userData.comparison_benchmark_percentile) {
    var comparison_benchmark_percentile = userData.comparison_benchmark_percentile / 100;
  } else {
    var comparison_benchmark_percentile = 0.5;
  }
  const comparisonData = {
    level: userData.Title,
    cfunction: userData.Function,
    stage: userData.Stage,
    region: userData.Region,
    percentile: comparison_benchmark_percentile
  }
  const offerData1 = {
    level: userData.Title,
    cfunction: userData.Function,
    stage: userData.Stage,
    region: userData.Region,
    percentile: userData.base_percentile
  }
  const competitionData1 = {
    level: userData.Title,
    cfunction: userData.Function,
    stage: userData.Stage,
    region: userData.Region,
    percentile: 0.75
  }
  dispatch(clearErrors());
  axios.all([
    axios.post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-compensation-estimate-post-form", comparisonData),
    axios.post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-compensation-estimate-post-form", offerData1),
    axios.post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-compensation-estimate-post-form", competitionData1)
  ])
    .then(axios.spread(function (comparisonGettingData, offerGettingData, competitionGettingData) {
      // comparison Data
      var comparisonGetData = comparisonGettingData.data.response
      var comparisonCash = comparisonGetData.base + comparisonGetData.bonus;
      var comparisonEquity = comparisonGetData.equity;
      var comparisionTotal = comparisonCash + comparisonEquity;
      var comaprisonPercentBase = Math.round((comparisonGetData.base / comparisionTotal) * 100);
      var comaprisonPercentBonus = Math.round((comparisonGetData.bonus / comparisionTotal) * 100);
      var comaprisonPercentEquity = Math.round((comparisonGetData.equity / comparisionTotal) * 100);

      var comparisonGetAllData = {
        "base": comparisonGetData.base,
        "bonuses": comparisonGetData.bonus,
        "equity": comparisonGetData.equity,
        "cash": comparisonCash,
        "total": comparisionTotal,
        "percent_base": comaprisonPercentBase * 100,
        "percent_bonuses": comaprisonPercentBonus * 100,
        "percent_equity": comaprisonPercentEquity * 100,
        "percent_equity_company": comparisonGetData.percent_equity_company
      }

      // offer getting data
      var offerGetData = offerGettingData.data.response
      var offerCash = offerGetData.base + offerGetData.bonus;
      var offerEquity = offerGetData.equity;
      var offerTotal = offerCash + offerEquity;
      var offerPercentBase = Math.round((offerGetData.base / offerTotal) * 100);
      var offerPercentBonus = Math.round((offerGetData.bonus / offerTotal) * 100);
      var offerPercentEquity = Math.round((offerGetData.equity / offerTotal) * 100);

      var offerGetAllData = {
        "base": offerGetData.base,
        "bonuses": offerGetData.bonus,
        "equity": offerGetData.equity,
        "cash": offerCash,
        "total": offerTotal,
        "percent_base": offerPercentBase * 100,
        "percent_bonuses": offerPercentBonus * 100,
        "percent_equity": offerPercentEquity * 100,
        "percent_equity_company": offerGetData.percent_equity_company
      }

      // competition getting data
      var competitionGetData = competitionGettingData.data.response
      var competitionCash = competitionGetData.base + competitionGetData.bonus;
      var competitionEquity = competitionGetData.equity;
      var competitionTotal = competitionCash + competitionEquity;
      var competitionPercentBase = competitionGetData.base / competitionTotal;
      var competitionPercentBonus = competitionGetData.bonus / competitionTotal;
      var competitionPercentEquity = competitionGetData.equity / competitionTotal;
      var competitionGetAllData = {
        "base": competitionGetData.base,
        "bonuses": competitionGetData.bonus,
        "equity": competitionGetData.equity,
        "cash": competitionCash,
        "total": competitionTotal,
        "percent_base": competitionPercentBase * 100,
        "percent_bonuses": competitionPercentBonus * 100,
        "percent_equity": competitionPercentEquity * 100,
        "percent_equity_company": competitionGetData.percent_equity_company
      }

      var estimateDashboardMLData = {
        message: "estimate ml data", data: {
          base_percentile: parseFloat(userData.base_percentile) * 100,
          comparison: comparisonGetAllData,
          offer: offerGetAllData,
          compensation: competitionGetAllData,
          value: parseFloat(userData.base_percentile) * 100,
        }
      }
      var base_percentile = parseFloat(userData.base_percentile) * 100;
      dispatch(setCurrentUserEstimateDashboard(estimateDashboardMLData));
    }))
    //.then(response => this.setState({ vehicles: response.data }))
    .catch(error => console.log(error));
};


// Estimate dashboard data
export const fnEstimateDashboard = (userData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/power-user-dashboard", userData)
    .then(res => {
      res.data.base_percentile = parseFloat(userData.base_percentile) * 100;
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export function getTempDataPowerUserDashboardDetails(userData) {
  var tmp_data = {
    user_id: userData
  };

  return axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-tmp-power-user-dashboard-data-grid", tmp_data)
}

export const inputValueAction = (name, value) => dispatch => {
  dispatch(clearErrors());
  dispatch(setCurrentUserInputValueAction(name, value));
};

export const offerDashDialogBoxAction = (name, value) => dispatch => {
  dispatch(clearErrors());
  dispatch(setCurrentUserOfferDashDialogBoxAction(name, value));
};

// percentage hidden action
export const offerDashPercentageHiddenAction = (name) => dispatch => {
  dispatch(clearErrors());
  dispatch(setCurrentUserOfferDashPercentageHiddenAction(name));
};

// percentage hidden action
export const offerDashAddMemeberIconAction = (name) => dispatch => {
  dispatch(clearErrors());
  dispatch(setCurrentUserAddMemeberIconAction(name));
};

export const fnAddMemberPowerUserDashboard = (userData, history) => dispatch => {
  dispatch(clearErrors());

  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-add-power-user-dashboard-get-form", userData)
    .then(res => {
      history.push("/home/estimate-dashboard")
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// add update temp power user dashboard

export const addUpdateTmpPowerUserDashboard = (userData) => dispatch => {
  dispatch(clearErrors());

  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-add-update-tmp-power-user", userData)
    .then(res => {
      console.info(res.data)
      dispatch(setAddUpdateTmpDashboard(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

//Create offer dashboard

// add update temp power user dashboard

export const addUpdateCreateOfferDashboard = (userData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-create-offer-get", userData)
    .then(res => {
      dispatch(setAddUpdateCreateOfferDashboard(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// roleInsertFlag action
export const offerDashRoleInsertFlagAction = (name) => dispatch => {
  dispatch(clearErrors());
  dispatch(setOfferDashRoleInsertFlagAction(name));
};

// offer Right side dashboard
export const offerDashRightSideDashboardAction = (base, bonus, equity, base_percentile, bonus_percentile, equity_percentile) => dispatch => {
  var rightSideData = {
    offer_base: base,
    offer_bonus: bonus,
    offer_equity: equity,
    offer_base_percentile: base_percentile,
    offer_bonus_percentile: bonus_percentile,
    offer_equity_percentile: equity_percentile
  }
  dispatch(clearErrors());
  dispatch(setOfferDashRightSideDashboardAction(rightSideData));
};

//UserProfile
export const setCurrentUserEstimateDashboard = (users) => {

  return {
    type: SET_CURRENT_ESTIMATE_DASHBOARD,
    payload: users
  };
};

//UserProfile
export const setCurrentUserInputValueAction = (name, value) => {

  return {
    type: SET_CURRENT_INPUT_ETIMATE_DASHBOARD,
    name: name,
    value: value
  };
};

//DialogBoxOfferDashBoard
export const setCurrentUserOfferDashDialogBoxAction = (name, value) => {

  return {
    type: SET_CURRENT_DIALOGBOX_OFFER_DASHBOARD,
    name: name,
    value: value
  };
};

//RightsidePercentageHiddenDashBoard
export const setCurrentUserOfferDashPercentageHiddenAction = (name) => {

  return {
    type: SET_CURRENT_PERCENTAGE_HIDDEN_OFFER_DASHBOARD,
    name: name
  };
};


// setCurrentUserAddMemeberIconAction
//RightsidePercentageHiddenDashBoard
export const setCurrentUserAddMemeberIconAction = (name) => {

  return {
    type: SET_CURRENT_ADD_MEMBER_ICON_OFFER_DASHBOARD,
    name: name
  };
};

export const setAddUpdateTmpDashboard = (name) => {

  return {
    type: SET_CURRENT_ADD_UPDATE_TMP_POWER_DASHBOARD,
    name: name
  };
};


export const setAddUpdateCreateOfferDashboard = (name) => {

  return {
    type: SET_CURRENT_ADD_UPDATE_CREATE_OFFER_DASHBOARD,
    payload: name
  };
};

export const setOfferDashRoleInsertFlagAction = (payload) => {

  return {
    type: SET_ROLE_INSERT_FLAG_OFFER_DASHBOARD,
    payload: payload
  };
};


export const setOfferDashRightSideDashboardAction = (payload) => {

  return {
    type: SET_ROLE_VALUES_RIGHT_SIDE_OFFER_DASHBOARD,
    payload: payload
  };
};
// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

export function getHRDetails(userData) {
  var id = userData;

  return axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-profile-get-form?id=" + id)
}