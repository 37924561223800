import axios from "axios";
import {
  SET_CURRENT_CANDIDATE_MEMBERS,
  GET_ROLE_CANDIDATE,
  GET_CANDIDATE_FROM_OFFER_DASHBOARD,
  CLEAR_ERRORS
} from "./types";

export const fnGetAllRoleCandidate = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidates-get-grid?id=" + id)
    .then(res => {
      const CandidateListData = res.data.candidates;
      const CandidateNewHireData = res.data.new_hire;
      const CandidateList = []
      for (let i = 0; i < CandidateListData.length; i++) {
        CandidateList.push(CandidateListData[i])
      }
      dispatch(setGetAllRoleCandidates(CandidateList, CandidateNewHireData));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export const fnGetAllCandidatesFromOfferDashboard = (id) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-dashboard-create-offer-candidates-get?new_hire_id=" + id)
    .then(res => {
      const CandidateListData = res.data.response;      
      const CandidateList = []
      for (let i = 0; i < CandidateListData.length; i++) {
        CandidateList.push(CandidateListData[i])
      }
      dispatch(setGetAllCandidatesFromOfferDashboard(CandidateList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export function fnDeleteRoleCandidate(id) {
  return axios
    .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidate-del?id=" + id)
}


export const FnInsertRoleCandidates = (insertRoleCandidate, history, new_hire_id) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidate-post-insert", insertRoleCandidate)
    .then(res => {
      const roleCandidateInsertData = res.data;
      dispatch(setInsertCandidates(roleCandidateInsertData));
      history.push("/home/add-candidate?id=" + new_hire_id)
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export function fnGetRoleCandidateMember(gerRoleCandidateData) {
  return axios
    .get('https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidate-get-form?role_candidate_id='+ gerRoleCandidateData.role_candidate_id +'&new_hire_id=' + gerRoleCandidateData.new_hire_id)
}


export const FnUpdateRoleCandidateMembers = (updateRoleCandidate, history, new_hire_id) => dispatch => {
  dispatch(clearErrors());

  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-candidate-post-update", updateRoleCandidate)
    .then(res => {
      const roleCandidateUpdateData = res.data;
      dispatch(setInsertCandidates(roleCandidateUpdateData));
      history.push("/home/add-candidate?id=" + new_hire_id)
    })
    .catch(err => {
      console.log(err)
    }
    );
};
//UserProfile
export const setInsertCandidates = (users) => {
  return {
    type: SET_CURRENT_CANDIDATE_MEMBERS,
    payload: users
  };
};

export const setGetAllRoleCandidates = (users, roleInfo) => {
  return {
    type: GET_ROLE_CANDIDATE,
    payload: users,
    roleInfo: roleInfo
  };
};

export const setGetAllCandidatesFromOfferDashboard = (users) => {
  return {
    type: GET_CANDIDATE_FROM_OFFER_DASHBOARD,
    payload: users
  };
};

// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};