import React, { Component } from 'react';
import logo from "../../img/menu-logo-white.png";
import { Link } from "react-router-dom";
import { sendEmailVerification } from "../../actions/authAction"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import '../../css/login.css';

class ResendEmailClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
    this.resendEmailSubmit = this.resendEmailSubmit.bind(this);
  }

  resendEmailSubmit = (event) => {
    event.preventDefault();
    let userData = {
      email: this.props.auth.user.email
    }
    sendEmailVerification(userData).then(response => {
      this.setState({
        message: response.data.message
      });
    }).catch(error => console.log(error));


  }


  render() {
    return (
      <div className="container-fluid landing">
        <div className="row">
          <div className="col-sm-9 col-md-9 col-xs-9 col-lg-9">
            {/* code for carousel */}
          </div>
          <div className="col-sm-3 col-md-3 col-xs-3 col-lg-3"
            style={{ backgroundColor: "rgba(255,255,255,.85)", height: "680px" }}>

            <img
              ref={(image) => this.image = image}
              src={logo}
              alt="Empty"
              style={{
                backgroundColor: "#3A4158",
                marginBottom: "51px",
                width: "90%",
                margin: "40px 0px 15px 20px"
              }}
            />

            <div className="row">
              <div className="col-md-12 col-xs-12 col-sm-6">
                <div className="">
                  <div className="card-block">
                    <form className="k-form" onSubmit={this.resendEmailSubmit} >
                      <fieldset >
                        <span style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: "#3c3b3b",
                          marginBottom: "-10px",
                          marginLeft: "-30px"
                        }}>Please verify your Email</span>
                        <br />
                      </fieldset>

                      <div style={{
                        marginTop: "-60px",
                        marginLeft: "-10%"
                      }}>
                        <input type="submit"
                          className="react-new-login-btn angular-new-login-btn-primary"
                          style={{ width: "110%" }}
                          value="Resend Email" />
                      </div>
                      <div className="" style={{ color: "red" }}>
                        {this.state.message}
                      </div>

                      <div>
                        <p className="paragraph-account"
                          style={{
                            color: "#777",
                            fontWeight: "lighter",
                            marginTop: "20px"
                          }}>
                          Need an account?
                                <Link style={{
                            color: "#22aae4", textDecoration: "none", fontWeight: "500",
                            marginLeft: "5px"
                          }}
                            to="/register">
                            Register here</Link>
                        </p>
                      </div>

                      <p className="text-muted"
                        style={{ marginTop: "80px", marginLeft: "-20px" }}>
                        Apollo is committed to securing and protecting your information. Find our privacy policy
                         <Link to="/register"
                          style={{
                            color: "#22aae4",
                            marginLeft: "5px"
                          }}>
                          here.
                        </Link>
                      </p>
                      <p className="text-muted"
                        style={{ marginBottom: "70px", marginLeft: "-20px" }}>
                        © Apollo, 2019.
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div >
          </div>
        </div>
      </div>
    );
  }
}

ResendEmailClass.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    errors: state.errors
  });

export default connect(mapStateToProps, { sendEmailVerification }
)(ResendEmailClass);