import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Link } from "react-router-dom";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import '../../../css/modal.css';

export default function MyRoleButtons(editField) {

  let visible = false;
  let confirm_url = '';

  function deleteSubmit(id, visible_value) {
    confirm_url = id;
    visible = visible_value;
  }

  function notConfirmDeleteSubmit(url, visible_value) {
    visible = visible_value;
  }

  return class extends GridCell {
    render() {
      const UPDATE_URL = "/home/update-candidate-form/" + this.props.dataItem.new_hire_id._id + "/" + this.props.dataItem["_id"]
      const DELETE_URL = "/home/delete-role-candidate?id=" + this.props.dataItem["_id"] + "&new_hire_id=" + this.props.dataItem.new_hire_id._id
      const NOT_CONFIRM_DELETE_URL = "/home/add-candidate?id=" + this.props.dataItem.new_hire_id._id

      return !this.props.dataItem[editField]
        ? (
          <td>
            <Link type="button" to={UPDATE_URL} title="Edit" className="k-button" >
              <span className="k-icon k-i-edit .k-i-pencil"></span>
            </Link>

            <Link type="button" title="Delete" className="k-button" id={DELETE_URL}
              onClick={() => deleteSubmit(DELETE_URL, true)}>
              <span className="k-icon k-i-delete .k-i-trash"></span>
            </Link>

            {visible && <Dialog title={"Please confirm"}>
              <p style={{ margin: "25px", textAlign: "center" }}>
                Are you sure you want to continue?
              </p>
              <DialogActionsBar>
                <Link className="k-button" onClick={() =>
                  notConfirmDeleteSubmit(NOT_CONFIRM_DELETE_URL, false)}
                  to={NOT_CONFIRM_DELETE_URL} >No</Link>
                <Link type="submit" className="k-button" to={confirm_url}>Yes</Link>
              </DialogActionsBar>
            </Dialog>}


          </td>
        )
        : null;
    }
  }
};
