import * as React from 'react';
import { Link } from "react-router-dom";
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { fnChangePassword } from "../../actions/authAction"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import '../../css/changePassword.css';
import FormValidator from '../../validation/FormValidator';
import validationParams from '../../validation/validationParams.json'
export class changePassword extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.changePasswordValidation);
    this.state = {
      success: false,
      oldPass: "",
      newPass: "",
      message: "",
      retypeNewPass: "",
      validation: this.validator.valid()
    };
    this.submitted = false;
    this.changePasswordSubmit = this.changePasswordSubmit.bind(this);
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changePasswordSubmit = (event) => {
    event.preventDefault();
    const { newPass, retypeNewPass } = this.state;
    // validate password and retype password
    if (newPass !== retypeNewPass) {
      this.setState({
        message: "Passwords do not match"
      });
      setTimeout(() => {
        this.setState({
          message: "",
          oldPass: "",
          newPass: "",
          retypeNewPass: ""
        });
      }, 2000);

    } else {
      const userData = {
        oldPassword: this.state.oldPass,
        newPassword: this.state.newPass,
        retypeNewPass: this.state.retypeNewPass,
        id: this.props.auth.user._id,
      };

      const validation = this.validator.validate(this.state);
      this.setState({ validation });
      this.submitted = true;
      if (validation.isValid) {
        fnChangePassword(userData).then(response => {
          this.setState({
            message: response.data.message
          });
        }).catch(error => console.log(error));
      }
      setTimeout(() => {
        this.setState({
          message: ""
        });
      }, 2000);
    }
  }
  render() {
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation
    return (
      <div className="row card-layout">
        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12 card-block">
          <form className="k-form" onSubmit={this.changePasswordSubmit}>
            <fieldset>
              <h1>Change Password</h1>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-xs-12 col-lg-1"></div>
                <div className="mb-3 col-md-5 col-sm-12 col-xs-12 col-lg-5">
                  <Input
                    name="oldPass"
                    type="password"
                    style={{ width: '100%' }}
                    onChange={this.onChange}
                    label="Old Password"
                    value={this.state.oldPass}
                    minLength={6}
                    maxLength={18}
                  />
                  <div style={{
                    color: "red"
                  }}>
                    {validation.oldPass.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-xs-12 col-lg-1"></div>
                <div className="mb-3 col-md-5 col-sm-12 col-xs-12 col-lg-5">
                  <Input
                    name="newPass"
                    type="password"
                    style={{ width: '100%' }}
                    onChange={this.onChange}
                    label="New Password"
                    value={this.state.newPass}
                    minLength={6}
                    maxLength={18}
                  />
                  <div style={{
                    color: "red"
                  }}>
                    {validation.newPass.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 col-sm-12 col-xs-12 col-lg-1"></div>
                <div className="mb-3 col-md-5 col-sm-12 col-xs-12 col-lg-5">
                  <Input
                    name="retypeNewPass"
                    type="password"
                    style={{ width: '100%' }}
                    onChange={this.onChange}
                    label="Retype New Password"
                    value={this.state.retypeNewPass}
                    minLength={6}
                    maxLength={18}
                  />
                  <div style={{
                    color: "red"
                  }}>
                    {validation.retypeNewPass.message}
                  </div>
                </div>

              </div>
            </fieldset>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2">
                <Button className="mt-3 col-md-12 col-xs-12 col-sm-12 col-lg-12" type="submit" primary={true} >Submit</Button>
              </div>
              <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2" style={{ border: "1px slid red" }}>
                <Link type="button" to="/home/change-password" className="k-button col-md-12 col-xs-12 col-sm-12 col-lg-12" style={{ marginTop: "16px" }}>CANCEL</Link>
              </div>
            </div>
          </form>
        </div>
        {this.state.message === "" ?
          <div></div>
          : (
            <div className="alert alert-success password-success-message">
              {this.state.message}
            </div>)}
        <div className="password-footer-div"><footer className="password-footer-tag">
          <p>Copyright © 2019 - ApolloGroup, Inc.</p>
        </footer>
        </div>
      </div>
    );
  }
}
changePassword.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  changePassword: state.changePassword,
  errors: state.errors
});
export default connect(mapStateToProps, { fnChangePassword }
)(changePassword);