import axios from "axios";
import { GET_HIRING_ROLE_LOOKUP, CLEAR_ERRORS, GET_STAGE_LOOKUP, GET_COMPANY_LOCATION_REGION_LOOKUP, GET_MARKET_SPACE_LOOKUP, GET_COMPANY_NO_OF_EMPLOYEES_LOOKUP, GET_COMPANY_LOOKUP, GET_TITLE_LOOKUP, GET_FUNCTION_LOOKUP, GET_DEGREE_LOOKUP, GET_GRADUATION_YEAR_LOOKUP, GET_COMPANY_MARKET_STAGE_REGION_LOOKUP, GET_Revenue_LOOKUP, GET_COMPANY_RELATED_DATA_LOOKUP, GET_ROLE_STATUS_LOOKUP, GET_CITY_LOOKUP, GET_STATE_LOOKUP, GET_REGION_LOOKUP } from "./types";

// Get Role Action
export const getHiringRoleLookUpData = () => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-hiring-role-list")
    .then(res => {
      dispatch(setHiringRoleLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get Stage action
export const getStageLookUpData = () => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-stage-list")
    .then(res => {
      dispatch(setStageLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get Company Location Region action
export const getCompanyLocationRegionLookUpData = () => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-get-company-location-region-data-list")
    .then(res => {
      const LocationRegionData = res.data.data;
      const Locations = []
      const States = []
      const Regions = []
      const locationRegiongCity = []
      for (let i = 0; i < LocationRegionData.length; i++) {
        Locations.push(LocationRegionData[i]["_id"]["location_city"])
        States.push(LocationRegionData[i]["_id"]["location_state"])
        Regions.push(LocationRegionData[i]["_id"]["region"])
        locationRegiongCity.push(LocationRegionData[i]["_id"])
      }
      dispatch(setCompanyLocationRegionLookUp(Locations, States, Regions, locationRegiongCity));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get Company Location Region action
export const getCompanyRelatedData = () => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-company-detail")
    .then(res => {
      const LocationRegionData = res.data.data;
      const Locations = []
      const MarketSpace = []
      const Stage = []
      const Employees = []
      const States = []
      const Regions = []
      const Competitors01 = []
      const companyRelatedData = []
      for (let i = 0; i < LocationRegionData.length; i++) {
        MarketSpace.push(LocationRegionData[i]["_id"]["marketspace"])
        Stage.push(LocationRegionData[i]["_id"]["stage"])
        Locations.push(LocationRegionData[i]["_id"]["location_city"])
        States.push(LocationRegionData[i]["_id"]["location_state"])
        Regions.push(LocationRegionData[i]["_id"]["region"])
        Regions.push(LocationRegionData[i]["_id"]["region"])
        Competitors01.push(LocationRegionData[i]["_id"]["Competitors01"])
        companyRelatedData.push(LocationRegionData[i]["_id"])
      }
      dispatch(setCompanyRelatedLookUp(Locations, MarketSpace, Stage, Employees, States, Regions, Competitors01, companyRelatedData));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get Market space action
export const getgraphAPIListMarketSpaceLookUpData = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-company-list?filter_name=marketspace")
    .then(res => {
      dispatch(setMarketSpaceLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get Market space action
export const getRevenueLookUpData = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-company-list?filter_name=revenue01")
    .then(res => {
      dispatch(setRevenueLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};



// Get No of employees company name lookup action
export const getcompanyLocationNoOfEmployeesLookUpData = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-company-detail")
    .then(res => {
      const CompanyEmployessData = res.data.data;
      const companyForEmployess = []
      const noOfEmployees = []
      const companyEmployeeId = []
      for (let i = 0; i < CompanyEmployessData.length; i++) {
        companyForEmployess.push(CompanyEmployessData[i]["_id"]["company"])
        noOfEmployees.push(CompanyEmployessData[i]["_id"]["employees"])
        companyEmployeeId.push(CompanyEmployessData[i]["_id"])
      }
      dispatch(setCompanyLocationNoOfEmployeesLookUp(companyForEmployess, noOfEmployees, companyEmployeeId));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get Company action
export const getCompanyLookUpData = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-company-list?filter_name=company")
    .then(res => {
      dispatch(setCompanyLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get AWS Lambda function API action
export const getAwsLambdaCompanyLookUpData = () => dispatch => {
  dispatch(clearErrors());
  const url = "https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-company-list?filter_name=company"; // site that doesn’t send Access-Control-*
  fetch(url)
    .then(response => response.json())
    .then(contents => dispatch(setCompanyLookUp(contents.data)))
    .catch(() => console.log("Can’t access " + url + " response. Blocked by browser?"))
};

// Get Company action
export const getTitleLookUpData = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-title-list")
    .then(res => {
      dispatch(setTitleLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};


// Get Function action
export const getFunctionLookUpData = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-function-list")
    .then(res => {
      dispatch(setFunctionLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get Degree action
export const getDegreeLookUp = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-degree-list")
    .then(res => {
      console.log("degree")
      console.log(res)
      console.log("degree")
      dispatch(setDegreeLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get Degree action
export const getGraduationYearLookUp = () => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-graduation-year-list")
    .then(res => {
      dispatch(setGraduationYearLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get Cpmpany Market Region Stage action
export const getCompanyMarketStageRegionLookUp = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-company-detail")
    .then(res => {
      dispatch(setCompanyMarketStageRegionLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get Cpmpany Market Region Stage action
export const getRoleStatusLookUp = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-role-status-list")
    .then(res => {
      dispatch(setRoleStatusLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get City action
export const getCityLookUp = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-company-list?filter_name=location_city")
    .then(res => {
      dispatch(setCityLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get State action
export const getStateLookUp = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-company-list?filter_name=location_state")
    .then(res => {
      dispatch(setStateLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

// Get State action
export const getRegionLookUp = () => dispatch => {
  dispatch(clearErrors());
  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-lkp-get-company-list?filter_name=region")
    .then(res => {
      dispatch(setRegionLookUp(res.data));
    })
    .catch(err => {
      console.log(err)
    }
    );
};
//Function Redux
export const setFunctionLookUp = (users) => {
  return {
    type: GET_FUNCTION_LOOKUP,
    payload: users
  };
};

//Company Redux
export const setCompanyLookUp = (users) => {
  return {
    type: GET_COMPANY_LOOKUP,
    payload: users
  };
};


//Title Redux
export const setTitleLookUp = (users) => {
  return {
    type: GET_TITLE_LOOKUP,
    payload: users
  };
};

//Hiring Role Redux
export const setHiringRoleLookUp = (users) => {
  return {
    type: GET_HIRING_ROLE_LOOKUP,
    payload: users
  };
};

//Stage Redux
export const setStageLookUp = (users) => {
  return {
    type: GET_STAGE_LOOKUP,
    payload: users
  };
};

//Stage Redux
export const setCompanyLocationRegionLookUp = (Locations, States, Regions, locationRegiongCity) => {
  return {
    type: GET_COMPANY_LOCATION_REGION_LOOKUP,
    Locations: Locations,
    States: States,
    Regions: Regions,
    locationRegiongCity: locationRegiongCity
  };
};

//Stage Redux
export const setCompanyRelatedLookUp = (Locations, MarketSpace, Stage, Employees, States, Regions, Competitors01, companyRelatedData) => {
  return {
    type: GET_COMPANY_RELATED_DATA_LOOKUP,
    Locations: Locations,
    MarketSpace: MarketSpace,
    Stage: Stage,
    Employees: Employees,
    States: States,
    Regions: Regions,
    Competitors01: Competitors01,
    companyRelatedData: companyRelatedData
  };
};

//Market Space Redux
export const setMarketSpaceLookUp = (users) => {
  return {
    type: GET_MARKET_SPACE_LOOKUP,
    payload: users
  };
};

//Revenue Space Redux
export const setRevenueLookUp = (users) => {
  return {
    type: GET_Revenue_LOOKUP,
    payload: users
  };
};

//Market Space Redux
export const setCompanyLocationNoOfEmployeesLookUp = (companyForEmployess, noOfEmployees, companyEmployeeId) => {
  return {
    type: GET_COMPANY_NO_OF_EMPLOYEES_LOOKUP,
    companyForEmployess: companyForEmployess,
    noOfEmployees: noOfEmployees,
    companyEmployeeId: companyEmployeeId
  };
};



//Market Space Redux
export const setDegreeLookUp = (users) => {
  return {
    type: GET_DEGREE_LOOKUP,
    payload: users
  };
};


//Market Space Redux
export const setGraduationYearLookUp = (users) => {
  return {
    type: GET_GRADUATION_YEAR_LOOKUP,
    payload: users
  };
};

//Company Market Stage Region Redux
export const setCompanyMarketStageRegionLookUp = (users) => {
  return {
    type: GET_COMPANY_MARKET_STAGE_REGION_LOOKUP,
    payload: users
  };
};

// Role status lookup
export const setRoleStatusLookUp = (users) => {
  return {
    type: GET_ROLE_STATUS_LOOKUP,
    payload: users
  };
};

// City lookup
export const setCityLookUp = (users) => {
  return {
    type: GET_CITY_LOOKUP,
    payload: users
  };
};

// State lookup
export const setStateLookUp = (users) => {
  return {
    type: GET_STATE_LOOKUP,
    payload: users
  };
};
// Region lookup

export const setRegionLookUp = (users) => {
  return {
    type: GET_REGION_LOOKUP,
    payload: users
  };
};
// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};