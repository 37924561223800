import React from 'react'
import { Link } from 'react-router-dom';
import logo from "../../img/apollo-logo-icon.png";
import '../../css/header.css';
import { withRouter } from 'react-router-dom';
import { logoutUser } from "../../actions/authAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = { userButton: false };
    }

    onSetSidebarOpen() {
        this.props.openSideBar();
    }

    onClick = () => {
        this.setState({
            userButton: !this.state.userButton,
        });
    }
    onMouseEnterHandler = () => {
        this.setState({
            userButton: true,
        });
    }
    handleMouseLeave = () => {
        this.setState({
            userButton: false
        });
    };
    onLogoutClick = (e) => {
        this.props.logoutUser(this.props.auth.user._id, this.props.history);
    }
    render() {
        const { userButton } = this.state;
        const children = userButton ? (
            <div className="ml-auto logout-button">
                <Link className="navbar-logout-link" onClick={this.onLogoutClick.bind(this)} onMouseEnter={this.onMouseEnterHandler} to="#" onMouseLeave={this.handleMouseLeave}><span className="k-icon k-i-logout Unicode: e131 logout-icon"></span>
                    LOGOUT
              </Link>
            </div >) : true;
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-style" >
                    {!this.props.sidebarOpen &&
                        <div>
                            <a className="navbar-brand" onClick={() => this.onSetSidebarOpen()} style={{ color: "white" }}>
                                <span className="k-icon k-i-reorder reorder-icon"></span>
                            </a>
                            <Link to="#">
                                <img src={logo} alt="Empty" className="header-logo" />
                            </Link>
                        </div>
                    }
                    {this.props.location.pathname === "/home/estimate-dashboard" ? <div className="navbar-content">Estimate Dashboard</div> :
                        <div className="header-empty" style={{ paddingLeft: "0px", paddingRight: "0px" }}></div>
                    }
                    {this.props.location.pathname === "/home/power-user-dashboard" ? <div className="navbar-content">Power User Dashboard</div> :
                        <div className="header-empty" style={{ paddingLeft: "0px", paddingRight: "0px" }}></div>
                    }
                    {this.props.location.pathname === "/home/candidate-dashboard" ? <div className="navbar-content">Personal Dashboard</div> :
                        <div className="header-empty" style={{ paddingLeft: "0px", paddingRight: "0px" }}></div>
                    }
                    {this.props.location.pathname === "/home/my-offer-dashboard" ? <div className="navbar-content">My Offer Dashboard</div> :
                        <div className="header-empty" style={{ paddingLeft: "0px", paddingRight: "0px" }}></div>
                    }
                    {this.props.location.pathname === "/home/explore-dashboard" ? <div className="navbar-content">Explore Dashboard</div> :
                        <div className="header-empty" style={{ paddingLeft: "0px", paddingRight: "0px" }}></div>
                    }
                    <div className="ml-auto">
                        <Link className="navbar-brand user-icon-link" to="#" onClick={this.onClick} onMouseEnter={this.onMouseEnterHandler} onMouseLeave={this.handleMouseLeave} style={{ color: "white" }} >
                            <span className="k-icon k-i-user user-icon"></span>  Hello, <b>{this.props.auth.userName}</b>
                            {
                                this.state.userButton
                                    ? <span className="k-icon k-i-arrow-60-up arrow-icon"></span>
                                    : <span className="k-icon k-i-arrow-60-down arrow-icon"></span>
                            }
                        </ Link>
                    </div>
                </nav>
                {children}
            </div>
        )
    }
}
Header.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const mapStateToProps = state =>
    ({
        auth: state.auth
    });
export default connect(
    mapStateToProps,
    { logoutUser }
)(withRouter(Header));
// export default withRouter(Header);