import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { AutoComplete} from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import '../../../css/My_Job_Profile.css';
import { Link } from "react-router-dom";
import FormValidator from '../../../validation/FormValidator'
import validationParams from '../../../validation/validationParams.json'
import LookUpData from './candidate.json'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { getTitleLookUpData, getFunctionLookUpData, getCompanyLocationRegionLookUpData, getDegreeLookUp, getGraduationYearLookUp } from "../../../actions/lookupAction";
import { titlesDataSort, functionalityDataSort, cityDataSort, regionDataSort, stateDataSort, degreeDataSort, graduationYearDataSort } from "../../dropdown/lookupSortingData";
import { fnGetCandidateProfile, FnInsertCandidatesProfile } from "../../../actions/candidateProfileAction"

export class MyJobProfileform extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.MyJobProfileformValidations);
    this.state = {
      success: false,
      function: "",
      base_salary: "",
      annual_bonus: "",
      equity_dollar_value: "",
      total_dollar_value: "",
      message: "",
      validation: this.validator.valid()
    };
    this.submitted = false;
    this.JobProfileFormSubmit = this.JobProfileFormSubmit.bind(this);
    this.props.getCompanyLocationRegionLookUpData();
    this.props.getTitleLookUpData();
    this.props.getFunctionLookUpData();
    this.props.getDegreeLookUp();
    this.props.getGraduationYearLookUp();
    fnGetCandidateProfile(this.props.auth.user._id).then(response => {
      let CandidateData = response.data.user

      this.state.location_state = CandidateData.location_state;
      this.state.location_city = CandidateData.location_city;
      this.state.title_level = CandidateData.overall_experience.title_level;
      this.state.job_function = CandidateData.overall_experience.job_function;
      this.state.total_years_of_experience = CandidateData.overall_experience.total_years_of_experience;
      this.state.base_salary = CandidateData.salary.base_salary;
      this.state.annual_bonus = CandidateData.salary.annual_bonus;
      this.state.equity_dollar_value = CandidateData.salary.equity_dollar_value;
      this.state.total_dollar_value = CandidateData.salary.total_dollar_value;
      this.state.equity_type_rsu = CandidateData.salary.equity_type_rsu;
      this.state.equity_type_stock = CandidateData.salary.equity_type_stock;
      this.state.candidate_accepted = CandidateData.salary.candidate_accepted;
      this.state.degree = CandidateData.education.degree;
      this.state.school = CandidateData.education.school;
      this.state.graduation_year = CandidateData.education.graduation_year;
      this.state.field_of_study = CandidateData.education.field_of_study;
      this.state.start_date = CandidateData.education.start_date;
      this.state.end_date = CandidateData.education.end_date;
    }).catch(error => console.log(error));
  }
  NumericChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
    })
  }

  onChange = e => {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
      stateLookupData: stateDataSort(value, this.props.lookups.companyStatesLookUp),
      cityLookupData: cityDataSort(value, this.props.lookups.companyLocationLookUp),
      regionLookupData: regionDataSort(value, this.props.lookups.companyRegionLookUp),
      titleLookupData: titlesDataSort(value, this.props.lookups.titleLookUp.data),
      functionalityLookupData: functionalityDataSort(value, this.props.lookups.functionLookUp.data),
      degreeLookupData: degreeDataSort(value, this.props.lookups.degreeLookUp.data),
      graduationYearLookupData: graduationYearDataSort(value, this.props.lookups.graduationYearLookUp.data)
    });
    if (e.target.name === "location_city") {
      const locationregioncity = this.props.lookups.locationRegiongCityLookUp;
      for (let i = 0; i < locationregioncity.length; i++) {
        if (locationregioncity[i]["location_city"] === e.target.value) {
          this.setState({
            location_state: locationregioncity[i]["location_state"],
            role_region: locationregioncity[i]["region"]
          });
        }
      }
    }
  };

  JobProfileFormSubmit = (event) => {
    event.preventDefault();
    const userData = {
      user_id: this.props.auth.user._id,
      location_state: this.state.location_state,
      location_city: this.state.location_city,
      overall_experience: {
        title_level: this.state.title_level,
        job_function: this.state.job_function,
        total_years_of_experience: this.state.total_years_of_experience
      },
      salary: {
        base_salary: this.state.base_salary,
        annual_bonus: this.state.annual_bonus,
        equity_dollar_value: this.state.equity_dollar_value,
        total_dollar_value: this.state.total_dollar_value,
        candidate_accepted: this.state.candidate_accepted,
        equity_type_rsu: this.state.equity_type_rsu,
        equity_type_stock: this.state.equity_type_stock,
      },
      education: {
        school: this.state.school,
        graduation_year: this.state.graduation_year,
        degree: this.state.degree,
        field_of_study: this.state.field_of_study,
        start_date: this.state.start_date,
        end_date: this.state.end_date
      }
    };
    if (this.state.base_salary === null) {
      this.state.base_salary = ""
    }
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      this.props.FnInsertCandidatesProfile(userData)
      const element = document.getElementById('top-scroll');
      element.scrollIntoView({ behavior: 'smooth' });
      this.setState({
        success: true,
      });
    }
    setTimeout(() => {
      this.setState({
        success: false
      });
    }, 2000);
  }
  toggleChange = () => {
    this.setState({
      equity_type_rsu: !this.state.equity_type_rsu,
    });
  }
  toggleChange2 = () => {
    this.setState({
      equity_type_stock: !this.state.equity_type_stock
    });
  }
  render() {
    let Totalvalue = parseInt(this.state.base_salary) + parseInt(this.state.annual_bonus) +
      parseInt(this.state.equity_dollar_value)
    this.state.total_dollar_value = Totalvalue;

    if (this.state.base_salary === null) {
      this.state.base_salary = ""
    }
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation

    if (this.props.candidateProfile.candidateProfile != null) {
      this.state.message = this.props.candidateProfile.candidateProfile.msg
    }

    return (
      <div className="example-wrapper maindiv-jobprofile" id="top-scroll">
        <div>
          {this.state.success && (
            <div
              className="alert alert-success"
              style={{
                position: 'fixed',
                bottom: "10px",
                right: "10px",
                backgroundColor: "#3b4156",
                animation: "cssAnimation 0s 1s forwards",
                padding: "10px",
                width: "25%",
                textAlign: "center",
                color: "white"
              }}
            >
              {this.state.message}
            </div>)}
        </div>
        <form className="k-form" onSubmit={this.JobProfileFormSubmit}>
          <fieldset>
            <h1 className="h1s">My Job Profile</h1>
            <h3 className="h3s">YOUR LOCATION </h3>
            <div className="company-inner-section">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <AutoComplete
                    name="location_city"
                    onChange={this.onChange}
                    data={this.state.cityLookupData}
                    value={this.state.location_city}
                    onFilterChange={this.onFilterChange}
                    label="City"
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="col-md-1"></div>
                <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <AutoComplete
                    name="location_state"
                    onChange={this.onChange}
                    data={this.state.stateLookupData}
                    value={this.state.location_state}
                    label="State"
                    style={{ width: '100%' }}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="company-inner-section">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <AutoComplete
                    name="role_region"
                    onChange={this.onChange}
                    required={true}
                    data={this.state.regionLookupData}
                    value={this.state.role_region}
                    disabled
                    placeholder="Region"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </div>
            <h3 className="h3experience">  OVERALL EXPERIENCE</h3>
            <div className="company-inner-section" >
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <AutoComplete
                    name="title_level"
                    onChange={this.onChange}
                    data={this.state.titleLookupData}
                    value={this.state.title_level}
                    label="Title/Level"
                    style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  />
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <AutoComplete
                    name="job_function"
                    onChange={this.onChange}
                    value={this.state.job_function}
                    data={this.state.functionalityLookupData}
                    label="Function *"
                    style={{ width: '100%' }}
                  />
                  <div style={{ color: "red" }}>
                    {validation.job_function.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="company-inner-section">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <AutoComplete
                    name="total_years_of_experience"
                    onChange={this.onChange}
                    data={LookUpData.Year_of_experience}
                    value={this.state.total_years_of_experience}
                    label="Total years of experience"
                    style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  />
                </div>
              </div>
            </div>
            <h3 className="h3s">YOUR SALARY </h3>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">

                <NumericTextBox
                  name="base_salary"
                  type="number"
                  spinners={false}
                  style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  onChange={this.NumericChange}
                  label="Base Salary *"
                  value={this.state.base_salary || null}
                />
                <div style={{ color: "red" }}>
                  {validation.base_salary.message}
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                <NumericTextBox
                  name="annual_bonus"
                  value={this.state.annual_bonus || null}
                  type="number"
                  spinners={false}
                  onChange={this.NumericChange}
                  style={{ width: '100%' }}
                  label="Annual Bonus "
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                <NumericTextBox
                  name="equity_dollar_value"
                  value={this.state.equity_dollar_value || null}
                  type="number"
                  spinners={false}

                  style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  onChange={this.NumericChange}
                  label="Annual Equity Value"
                />
              </div>
              <div className="col-md-1"></div>
              <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                <NumericTextBox
                  name="total_dollar_value"
                  value={this.state.total_dollar_value || null}
                  type="number"
                  spinners={false}

                  disabled
                  onChange={this.NumericChange}
                  style={{ width: '100%' }}
                  label="Total Value"
                />
              </div>
            </div>
            <div className="row mb-3" >
              <div className="col-md-1"></div>
              <div className="col-md-3">Types of Equity</div>
              <div className="mb-3 col-md-3 col-lg-3 col-sm-6 col-xs-6" id="checkbox2" style={{ marginBottom: "10px", width: "40%", paddingTop: "0px", marginRight: "25px" }}>
                <input className="k-checkbox"
                  onChange={this.toggleChange} checked={this.state.equity_type_rsu} name="equity_type_rsu" type="checkbox" id="auth-2fa" />
                <label className="k-checkbox-label" htmlFor="auth-2fa">  RSUs. </label>
              </div>
              <div className="col-md-5 col-lg-5 col-sm-6 col-xs-6" id="checkbox3" style={{ paddingLeft: "0px", paddingTop: "0px" }}>
                <input className="k-checkbox" type="checkbox" name="equity_type_stock"
                  onChange={this.toggleChange2} checked={this.state.equity_type_stock} id="auth-2fb" />
                <label className="k-checkbox-label" htmlFor="auth-2fb">Stock options </label>
              </div>
            </div>
            <h3 className="h3details"> YOUR EDUCATION </h3>
            <div className="company-inner-section" style={{ marginLeft: "5px", marginBottom: "0px" }}>
              <div className="row">
                <div className="col-md-1"></div>
                <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <Input
                    name="school"
                    onChange={this.onChange}
                    label="School"
                    value={this.state.school}
                    style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  />
                </div>
                <div className="col-md-1"></div>
                <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <AutoComplete
                    name="graduation_year"
                    onChange={this.onChange}
                    data={this.state.graduationYearLookupData}
                    value={this.state.graduation_year}
                    label="Graduation year"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </div>
            <div className="company-inner-section" style={{ marginLeft: "5px" }}>
              <div className="row">
                <div className="col-md-1"></div>
                <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <AutoComplete
                    name="degree"
                    onChange={this.onChange}
                    label="Degree"
                    data={this.state.degreeLookupData}
                    value={this.state.degree}
                    style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  />
                </div>
                <div className="col-md-1"></div>
                <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <Input
                    name="field_of_study"
                    onChange={this.onChange}
                    value={this.state.field_of_study}
                    label="Field of Study"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </div>
            <div className="company-inner-section" >
              <div className="row">
                <div className="col-md-1"></div>
                <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <Input
                    name="start_date"
                    style={{ width: '100%' }}
                    type="date"
                    spinners={false}
                    onChange={this.onChange}
                    label="Start date"
                    format="MM/dd/yyyy"
                    defaultValue={new Date()}
                    value={this.state.start_date}

                  />
                </div>
                <div className="col-md-1"></div>
                <div className="mb-3 col-md-4 col-lg-4 col-sm-12 col-xs-12">
                  <Input
                    style={{ width: '100%' }}
                    name="end_date"
                    type="date"
                    spinners={false}
                    label="End date"
                    format="MM/dd/yyyy"
                    onChange={this.onChange}
                    defaultValue={new Date()}
                    value={this.state.end_date}

                  />                </div>
              </div>
            </div>
          </fieldset>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2">
              <Button className="mt-3 col-md-12 col-xs-12 col-sm-12 col-lg-12" type="submit" primary={true} >Save</Button>
            </div>
            <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2" style={{ border: "1px slid red" }}>
              <Link type="button" to="/home/add-candidate" className="k-button col-md-12 col-xs-12 col-sm-12 col-lg-12" style={{ marginTop: "16px" }}>CANCEL</Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
MyJobProfileform.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  MyJobProfileform: state.MyJobProfileform,
  lookups: state.lookups,
  candidateProfile: state.candidateProfile,
  errors: state.errors
});
export default connect(mapStateToProps, { getCompanyLocationRegionLookUpData, getTitleLookUpData, getFunctionLookUpData, getDegreeLookUp, getGraduationYearLookUp, fnGetCandidateProfile, FnInsertCandidatesProfile })(MyJobProfileform);