import React from 'react'
import ReactDOM from 'react-dom';
import '../../css/dashboard.css';
import initialStateParams from '../../validation/validationParams.json';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DashBoardInputs from './DashboardInputs';
import LineChartComponent from './LineChart';
import OfferLineChartComponent from '../dashboard/companyDashboard/offerdashboard/OfferdashboardLineChart'
import RightSide from './RightSideDashboard';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import OfferRightSide from './companyDashboard/offerdashboard/OfferRightSideDashboard';
import OfferDashboardInputs from './companyDashboard/offerdashboard/offerDashboardInputs'
import OfferDashboardCandidateGrid from './companyDashboard/offerdashboard/offerDashboardCandidateGrid'
import {
    getHRDetails
} from "../dropdown/lookups";
import { fnGetNewHires } from "../../actions/roleActions";
import { offerDashAddMemeberIconAction } from "../../actions/dashboard";
import DashPowerUserBoardInputs from '../dashboard/powerUserDashboard/DashPowerUserBoardInputs';
import { candidateDataAction } from "../../actions/candidateOffersAction";
export class DashBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialStateParams.estimateDashBoard
        this.state.success = false
    }
    handleSelect = (e) => {
        this.setState({ selected: e.selected })
        this.props.offerDashAddMemeberIconAction(e.selected)
    }
    componentDidMount() {
        this.state.success = this.props.profile.profile.message;

        if (this.props.profile.profile.message !== undefined) {
            this.setState({
                success: this.props.profile.profile.message
            })
            setTimeout(() => {
                this.props.profile.profile.message = false
                this.setState({
                    success: false
                });
                ReactDOM.findDOMNode(this).scrollIntoView();
            }, 1000)
        }
        if (this.props.location.search !== "") {
            fnGetNewHires(this.props.location.search.slice(9)).then(response => {
                if (response.data) {
                    this.setState({
                        Company: response.data.response.role_company,
                        Stage: response.data.response.role_stage,
                        Region: response.data.response.role_region,
                        MarketSpace: response.data.response.role_market_space
                    })
                }
            }).catch(error => console.log(error));
        } else {
            getHRDetails(this.props.auth.user._id).then(response => {
                if (response.data) {
                    this.setState({
                        Company: response.data.msg.company_name,
                        Stage: response.data.msg.company_stage,
                        Region: response.data.msg.role_region,
                        MarketSpace: response.data.msg.company_market_space
                    })
                }
            }).catch(error => console.log(error));
        }

    }
    render() {
        var inputValues = {
            Company: this.state.Company,
            Stage: this.state.Stage,
            Region: this.state.Region,
            MarketSpace: this.state.MarketSpace
        }
        var history = this.props.history
        var location = this.props.location

        if (this.props.location.search !== "") {
            var user_id = {
                user_id: this.props.location.search.slice(9)
            }
        }
        if (this.props.estimateDashboard.addMemberInviteButton === 1) {
            this.state.selected = 1
        }
        if (this.props.estimateDashboard.addMemberInviteButton === 2) {
            this.state.selected = 2
        }
        if (this.props.estimateDashboard.addMemberInviteButton === 0) {
            this.state.selected = 0
        }
        return (
            <div className="container-fluid role-main-div">
                <div>
                    {this.state.success && (
                        <div
                            className="alert alert-success role-alert-message"
                            style={{
                                bottom: "0px",
                                right: "10px",
                                position: "fixed"
                            }}
                        >
                            {this.state.success}
                        </div>)}
                </div>

                <TabStrip selected={this.state.selected} onSelect={this.handleSelect} className="col-sm-12 col-xs-12 col-md-12 col-lg-12 example-col Tabstripcontent" style={{ margin: "2px", marginLeft: "0px", paddingLeft: "10px", marginTop: "25px" }
                }>
                    <TabStripTab title="ESTIMATE" >

                        <div className="" style={{ padding: "0px", paddingTop: "0px" }}>
                            <div className="row" style={{ border: "1px solid #d9d9d9", margin: "0px" }}>
                                <div className="col-md-9 dashboard-left">
                                    {/* <FixedDashboardHead headParams={this.state} history={history} /> */}
                                    <DashBoardInputs value={initialStateParams.estimateDashBoard} user_id={user_id} inputValues={inputValues} location={location} history={history} />
                                    <LineChartComponent value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                                </div>
                                <div className="col-md-3 dashboard-right">
                                    <RightSide value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                                </div>
                            </div>
                        </div>
                    </TabStripTab>
                    <TabStripTab title="POWER-USER">
                        <div className="power-user-dashboard-container" style={{ paddingLeft: "16px", paddingTop: "0px", margin: "0px", paddingRight: "0px" }}>
                            <div className="row power-user-dashboard-row" >
                                <div className="col-md-9">
                                    <DashPowerUserBoardInputs value={initialStateParams.estimateDashBoard} inputValues={inputValues} history={history} />
                                    <LineChartComponent value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                                </div>
                                <div className="col-md-3 dashboard-right">
                                    <RightSide value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                                </div>
                            </div>
                        </div>
                    </TabStripTab>
                    <TabStripTab title="CREATE-OFFER">
                        <div className="" style={{ padding: "0px", paddingTop: "0px" }}>
                            <div className="row" style={{ border: "1px solid #d9d9d9", margin: "0px" }}>
                                <div className="col-md-9 dashboard-left">
                                    <OfferDashboardInputs value={initialStateParams.estimateDashBoard} inputValues={inputValues} location={location} history={history} />
                                    <OfferLineChartComponent value={initialStateParams.estimateDashBoard} inputValues={inputValues} location={location} />
                                </div>
                                <div className="col-md-3 dashboard-right">
                                    <OfferRightSide value={initialStateParams.estimateDashBoard} inputValues={inputValues} />
                                </div>
                                <div className="col-md-12"><OfferDashboardCandidateGrid location={location} history={history} /></div>
                            </div>
                        </div>
                    </TabStripTab>
                    <TabStripTab title="MY OFFERS">
                        <p>Tab 4 Content</p>
                    </TabStripTab>
                </TabStrip >
            </div>

        )
    }
}

DashBoard.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state =>
    ({
        auth: state.auth,
        errors: state.errors,
        estimateDashboard: state.estimateDashboard,
        lookups: state.lookups,
        profile: state.profile
    });
export default connect(mapStateToProps, { offerDashAddMemeberIconAction, candidateDataAction }
)(DashBoard);
