import React from 'react'
import '../../../css/dashboard.css';
import { connect } from "react-redux";
import 'hammerjs';
import { inputValueAction, offerDashDialogBoxAction } from "../../../actions/dashboard";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import FormValidator from '../../../validation/FormValidator';
import validationParams from '../../../validation/validationParams.json';
import { DropDownList } from '@progress/kendo-react-dropdowns';
export class OfferDashOfferSave extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.powerUserAddCompanyNameValidations);
    this.state = {
      visible: true,
      company_name: "",
      last_name: "",
      email: "",
      validation: this.validator.valid()
    }
    this.submitted = false;
  }
  AddNewCompanySubmit = (event) => {
    event.preventDefault();
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      console.log("hello")
    }
  }
  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible
    });
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    if (this.state.validation.company_name.message === "Company Name is required.") {
      this.state.validation.company_name.message = ""
    }
    this.setState({
      change_field: false,
      company_name: this.props.companyValue
    })
    this.submitted = false;
    this.props.offerDashDialogBoxAction(this.state.visible, false)
  }
  onChange = e => {
    const value = e.target.value;
    this.state[e.target.name] = value;
    if (e.target.name === "company_name") {
      this.setState({
        change_field: true
      })
    }
  }
  render() {
    if (this.props.estimateDashboard.togglename === "new_company") {
      this.state.visible = true
    } else {
      this.state.visible = false
    }
    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation
    console.log(this.state)
    return (
      <div>
        {this.state.visible && <Dialog className="div-modal dialog-font-size" title={"Add a Company"} onClose={this.toggleDialog}>
          <p style={{ margin: "25px", fontSize: "16px" }}>Please complete the form to add a new company!</p>
          <form action="" onSubmit={this.AddNewCompanySubmit} >
            <div className="offer-save-dialog-input-div" style={{ paddingLeft: "25px", marginBottom: "0px" }}>

              <div className="col-md-12 col-sm-11 col-xs-11 col-lg-12 offer-save-input-field" style={{ marginBottom: "0px" }}>
                {this.state.change_field === true ? <Input
                  name="company_name"
                  type="text"
                  style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                  onChange={this.onChange}
                  label="Company Name*"
                  value={this.props.company_name}
                /> : <Input
                    name="company_name"
                    type="text"
                    style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                    onChange={this.onChange}
                    label="Company Name*"
                    value={this.props.companyValue}
                  />}

                <div style={{ color: "red", paddingLeft: "0px" }}>
                  {validation.company_name.message}
                </div>
              </div>

              <div className="mb-5 col-md-12 col-sm-11 col-xs-11 col-lg-12 offer-save-input-field" style={{ marginTop: "10px", paddingTop: "30px" }}>
                <DropDownList
                  type="text"
                  style={{ width: '100%' }}
                  onChange={this.onChange}
                  label="MarketSpace"
                  data={this.props.marketSpaceLookupData}
                />

              </div>
              <div style={{ display: "flex", paddingBottom: "0px" }}>
                <div className="mb-3 col-md-6 col-sm-11 col-xs-11 col-lg-6 offer-save-input-field" style={{ marginTop: "10px", marginBottom: "0px" }}>
                  <DropDownList
                    type="text"
                    style={{ width: '100%' }}
                    onChange={this.onChange}
                    label="Stage"
                    data={this.props.stageLookupData}
                  />
                </div>
                <div className="mb-3 col-md-6 col-sm-11 col-xs-11 col-lg-6 offer-save-input-field" style={{ marginTop: "10px", marginBottom: "0px" }}>
                  <DropDownList
                    type="text"
                    style={{ width: '100%' }}
                    onChange={this.onChange}
                    label="Location"
                    data={this.props.regionLookupData}
                  />
                </div>
              </div>
              <div> <p className="md-caption"> We'll review and notify if we need more information</p></div>
            </div>

            <div className="power-dash-save-buttons-div">
              <DialogActionsBar >
                <button className="k-button col-xs-6 col-sm-6" onClick={this.toggleDialog}>CANCEL</button>
                <button type="submit" className="k-button col-xs-6 col-sm-6" >SAVE</button>
              </DialogActionsBar>
            </div>
          </form>


        </Dialog>}
      </div >

    )
  }
}
const mapStateToProps = state =>
  ({
    estimateDashboard: state.estimateDashboard,
  });
export default connect(mapStateToProps, { inputValueAction, offerDashDialogBoxAction }
)(OfferDashOfferSave);
