import axios from "axios";
import { SET_CANDIDATE_PROFILE, CLEAR_ERRORS } from "./types";


export function fnGetCandidateProfile(id) {
  return axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-candidate-profile-get-form?user_id=" + id)
}

export const FnInsertCandidatesProfile = (CandidateProfileData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-candidate-profile-post-insert-update", CandidateProfileData)
    .then(res => {
      const CandidateProfileSubmitData = res.data;
      dispatch(setCandidatesProfile(CandidateProfileSubmitData));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

//UserProfile
export const setCandidatesProfile = (users) => {
  return {
    type: SET_CANDIDATE_PROFILE,
    payload: users
  };
};

// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};