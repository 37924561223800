import React from 'react'
import '../../../css/dashboard.css';
import { connect } from "react-redux";
import { DropDownList, AutoComplete } from '@progress/kendo-react-dropdowns';
import {
    getTitleLookUpData,
    getFunctionLookUpData,
    getCompanyLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getStageLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData,
    getCompanyMarketStageRegionLookUp
} from "../../../actions/lookupAction";
import { inputValueAction } from "../../../actions/dashboard";
import constValues from '../../dashboard/dashboard.json';
import { FnInsertRole } from "../../../actions/roleActions";
import { NumericTextBox } from '@progress/kendo-react-inputs';
class offerDashboardInputs extends React.Component {
    constructor(props) {
        super(props);
        this.props.getTitleLookUpData();
        this.props.getFunctionLookUpData();
        this.props.getCompanyLookUpData();
        this.props.getgraphAPIListMarketSpaceLookUpData();
        this.props.getCompanyLocationRegionLookUpData();
        this.props.getcompanyLocationNoOfEmployeesLookUpData();
        this.props.getStageLookUpData();
        this.props.getCompanyMarketStageRegionLookUp();
        this.state = {
            Title: this.props.value.Title,
            Function: this.props.value.Function,
            Company: this.props.value.Company,
            Region: this.props.value.Region
        };
    }

    render() {
        return (
            <div className="">
                <div className="row fixed-dashboards offer-fixed-dashboard" style={{ marginRight: "0px", width: "106%" }}>
                    <div className="col-md-10  col-sm-6 col-xs-6 col-lg-10 paddingRight" style={{ fontSize: "18px" }} >
                        <h3 style={{ fontWeight: "300", fontSize: "18px" }}>
                            Talent Market: {this.state.Title}, {this.state.Function} - {this.state.Company} - {this.state.Region}</h3>
                    </div>
                    <div className="col-md-2 col-sm-6 col-xs-6 col-lg-2 paddingRight">
                        <div className="float-right power_user_dashboard-icon-save" style={{ cursor: "pointer" }}>
                            <i title="Invite" className="k-icon k-i-flip-vertical" aria-hidden="true"
                                style={{
                                    margin: "auto", color: "rgba(255,255,255,0.87)", fontSize: "24px", lineHeight: "1", marginLeft: "10px", marginTop: "6px"
                                }}
                            >
                            </i>
                        </div>
                        <div className="float-right power_user_dashboard-icon-save" style={{ cursor: "pointer" }}>
                            <i title="Add Member" className="fa fa-floppy-o" aria-hidden="true"
                                style={{
                                    margin: "auto", color: "rgba(255,255,255,0.87)", fontSize: "24px", lineHeight: "1", marginLeft: "10px", marginTop: "6px"
                                }}
                            >
                            </i>
                        </div>
                    </div>
                </div>
                <div className="row fixed-dashboards dashboard-input-wrapper" style={{ paddingLeft: "5px" }}>
                    <div className="col-md-3 paddingRight"  >
                        <div className="div-currentOffer">
                            <div className="font-currentOffer">OFFER</div>
                            <div className="font-currentOffer-dollar"><small style={{ fontSize: "60%" }}>$</small>  0</div>
                        </div>
                    </div>
                    <div className="col-md-3 paddingRight">
                        <div className="div-finalist">
                            <div className="font-finalist">YOU</div>
                            <div className="font-finalist-dollar" ><small style={{ fontSize: "60%" }}>$</small>  0</div>
                        </div>
                    </div>
                    <div className="col-md-3 paddingRight">
                        <div className="div-candidatepool">
                            <div style={{ marginBottom: "5px" }}>
                                <DropDownList
                                    data={constValues.rateOfferdashboard}
                                    defaultValue="PEOPLE LIKE ME"
                                    style={{ width: "100%", fontSize: "14PX", borderStyle: "none" }}
                                />
                            </div>
                            <div className="font-candidatepool-offerstrength-dollar"><small style={{ fontSize: "60%" }}>$</small>  0</div>
                        </div>
                    </div>
                    <div className="col-md-3 paddingRight">
                        <div className="div-offerstrength">
                            <div className="font-offerstrength" >RATE</div>
                            <div className="font-candidatepool-offerstrength-dollar"> 0%</div>
                        </div>
                    </div>

                </div>

                <div className="row fixed-dashboards dashboard-input-wrapper">
                    <div className="col-md-3 paddingRight">
                        <AutoComplete
                            name="Company"
                            onChange={this.onChange}
                            required={true}
                            data={this.state.companyLookUpData}
                            label="Company"
                            value={this.state.Company}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <AutoComplete
                            name="Title"
                            onChange={this.onChange}
                            required={true}
                            data={this.state.titleLookupData}
                            label="Title"
                            value={this.state.Title}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <AutoComplete
                            name="Function"
                            onChange={this.onChange}
                            data={this.state.functionalityLookupData}
                            label="Function"
                            value={this.state.Function}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3 paddingRight">
                        <NumericTextBox
                            name="CurrentTotal"
                            onChange={this.onChange}
                            required={true}
                            data={this.state.functionalityLookupData}
                            label="Current Total"
                            value="300000"
                            style={{ width: '100%' }}
                            disabled
                        />
                    </div>
                    <br /><br /><br /><br /><br />
                    <div className="col-md-3 paddingRight numeric-input-textbox-width">
                        <NumericTextBox
                            name="Base"
                            onChange={this.onChange}
                            required={true}
                            data={this.state.titleLookupData}
                            label="Base"
                            value="200000"
                            style={{ width: '10px' }}
                        />
                    </div>
                    <div className="col-md-3 paddingRight numeric-input-textbox-width">
                        <NumericTextBox
                            name="Bonus"
                            onChange={this.onChange}
                            required={true}
                            data={this.state.functionalityLookupData}
                            label="Bonus"
                            value="50000"
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3 paddingRight numeric-input-textbox-width">
                        <NumericTextBox
                            name="Equity"
                            onChange={this.onChange}
                            required={true}
                            data={this.state.titleLookupData}
                            label="Equity %"
                            format="p0"
                            value="0.5"
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-md-3 paddingRight numeric-input-textbox-width">
                        <NumericTextBox
                            name="AnnualEquity"
                            onChange={this.onChange}
                            required={true}
                            data={this.state.functionalityLookupData}
                            label="Annual Equity"
                            value="50000"
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>

            </div >
        )
    }
}


const mapStateToProps = state =>
    ({
        lookups: state.lookups,
        auth: state.auth,
        estimateDashboard: state.estimateDashboard,
        powerUserDashBoard: state.powerUserDashBoard
    });
export default connect(mapStateToProps, {
    getTitleLookUpData,
    getFunctionLookUpData,
    getCompanyLookUpData,
    getgraphAPIListMarketSpaceLookUpData,
    getCompanyLocationRegionLookUpData,
    getcompanyLocationNoOfEmployeesLookUpData,
    getStageLookUpData,
    getCompanyMarketStageRegionLookUp,
    inputValueAction,
    FnInsertRole
}
)(offerDashboardInputs);
