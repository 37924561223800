import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import MyRoleButtons from './GridActionButtons';
import {
  fnGetAllHiringTeamMembers,
  fnDeleteHiringTeamMember
} from "../../../actions/roleHiringTeamMemberAction";
import "../../../css/roleGrid.css";
import { fnGetNewHires } from "../../../actions/roleActions";

class HRGrid extends React.Component {
  RoleButtons;
  constructor(props) {
    super(props);
    this.state = {
      RolesList: [],
      success: false,
      skip: 0, take: 10, sort: [
        { field: 'first_name', dir: 'asc' }
      ],
      new_hire_id: ""
    };
    this.RoleButtons = MyRoleButtons("inEdit");
  }
  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  }

  componentDidMount() {
    fnGetNewHires(this.props.location.search.slice(4)).then(response => {
      this.setState({
        role_title: response.data.response.role_title,
        role_function: response.data.response.role_function
      })
    }).catch(error => console.log(error));
    if (this.props.hiringMembers.RoleHrSuccessData !== null) {
      this.setState({
        success: this.props.hiringMembers.RoleHrSuccessData.message
      })
      setTimeout(() => {
        this.props.hiringMembers.RoleHrSuccessData = false
        this.setState({
          success: false
        });
      }, 1000)
    }
    let data = {
      hr_id: this.props.auth.user._id,
      new_hire_id: this.props.history.location.search.slice(4)
    }
    if (this.props.location.pathname === "/home/delete-hiring-member") {

      let id = this.props.location.search.split("=")[1].split("&")[0]
      let new_hire_id = this.props.location.search.split("=")[2]
      let data = {
        invitee_hiring_member_id: id,
        new_hire_id: new_hire_id
      }
      fnDeleteHiringTeamMember(data).then(response => {
        this.setState({
          success: response.data.message
        })
        setTimeout(() => {
          this.props.history.push("/home/add-hr?id=" + new_hire_id)
          this.setState({
            success: false
          });
        }, 500)
      }
      ).catch(error => console.log(error));
    } else {
      this.props.fnGetAllHiringTeamMembers(data);
    }
  }

  render() {
    if (this.props.hiringMembers.hiringMembersData === null) {
      this.state.RolesList = []
    } else {
      this.state.RolesList = this.props.hiringMembers.hiringMembersData;
    }
    const role_id = "/home/add-hr-form/" + this.props.history.location.search.slice(4)
    return (
      <div className="container-fluid role-main-div">
        <div className="role-main-plus-button"> Hiring for {this.state.role_title} - {this.state.role_function}
          <Link
            title="Add" type="button" to={role_id} className="k-button role-main-Link-plus-button" ><span className="k-icon k-i-plus"></span></Link>
        </div>
        <div>
          {this.state.success && (
            <div
              className="alert alert-success role-alert-message"
              style={{
                bottom: "10px",
                right: "10px"
              }}
            >
              {this.state.success}
            </div>)}
        </div>
        <Grid
          className="role-grid"
          data={orderBy(this.state.RolesList.slice(this.state.skip, this.state.take + this.state.skip), this.state.sort)}
          skip={this.state.skip}
          take={this.state.take}
          total={this.state.RolesList.length}
          pageable={true}
          onPageChange={this.pageChange}
          sortable={true}
          sort={this.state.sort}
          onSortChange={(e) => {
            this.setState({
              sort: e.sort
            });
          }}
        >
          <Column field="first_name" title="FIRST NAME" />
          <Column field="last_name" filter="numeric" format="" title="LAST NAME" />
          <Column field="company_name" filter="numeric" title="COMPANY" />
          <Column cell={this.RoleButtons} title="ACTION" />
        </Grid>
      </div>
    );
  }
}




HRGrid.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hiringMembers: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  hiringMembers: state.hiringMembers
});

export default connect(mapStateToProps, { fnGetAllHiringTeamMembers }
)(HRGrid);