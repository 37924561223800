import React from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import "../../../css/roleGrid.css";
import {
  fnGetAllRoleCandidate,
  fnDeleteRoleCandidate
} from "../../../actions/roleCandidateAction";
import MyRoleButtons from './GridActionButtons';
import { fnGetNewHires } from "../../../actions/roleActions";
class CandidateGrid extends React.Component {
  RoleButtons;
  constructor(props) {
    super(props);
    this.state = {
      CandidateList: [],
      CandidateNewRolesList: [],
      success: false,
      skip: 0, take: 10, sort: [
        { field: 'role_title', dir: 'asc' }
      ]
    };
    this.RoleButtons = MyRoleButtons("inEdit");
  }
  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  }

  componentDidMount() {
    fnGetNewHires(this.props.location.search.slice(4)).then(response => {
      this.setState({
        role_title: response.data.response.role_title,
        role_function: response.data.response.role_function
      })
    }).catch(error => console.log(error));
    if (this.props.candidates.candidates !== null) {
      this.setState({
        success: this.props.candidates.candidates.message
      })
      setTimeout(() => {
        this.props.candidates.candidates = false
        this.setState({
          success: false
        });
      }, 1000)
    }

    if (this.props.location.pathname === "/home/delete-role-candidate") {

      let id = this.props.location.search.split("=")[1].split("&")[0]
      let new_hire_id = this.props.location.search.split("=")[2]

      fnDeleteRoleCandidate(id).then(response => {
        this.setState({
          success: response.data.message
        })
        setTimeout(() => {
          this.props.history.push("/home/add-candidate?id=" + new_hire_id)
          this.setState({
            success: false
          });
        }, 500)
      }
      ).catch(error => console.log(error));
    } else {
      let id = this.props.history.location.search.slice(4);
      this.props.fnGetAllRoleCandidate(id);
    }

  }

  render() {
    if (this.props.candidates.candidatesData === null) {
      this.state.CandidateList = []
    } else {
      this.state.CandidateList = this.props.candidates.candidatesData;
      this.state.CandidateNewRolesList = this.props.candidates.candidatesRoleInfoData;
    }
    let ADD_CANDIDATE_FORM_URL = "/home/add-candidate-form/" + this.props.location.search.slice(4)
    return (
      <div className="container-fluid role-main-div">
        <div className="role-main-plus-button"> Candidates for {this.state.role_title} - {this.state.role_function}
          <Link
            title="Add" type="button" to={ADD_CANDIDATE_FORM_URL} className="k-button role-main-Link-plus-button" ><span className="k-icon k-i-plus"></span></Link>
        </div>
        <div>
          {this.state.success && (
            <div
              className="alert alert-success role-alert-message"
              style={{
                bottom: "10px",
                right: "10px"
              }}
            >
              {this.state.success}
            </div>)}
        </div>
        <Grid
          className="role-grid"
          data={orderBy(this.state.CandidateList.slice(this.state.skip, this.state.take + this.state.skip), this.state.sort)}
          skip={this.state.skip}
          take={this.state.take}
          total={this.state.CandidateNewRolesList.length}
          pageable={true}
          onPageChange={this.pageChange}
          sortable={true}
          sort={this.state.sort}
          onSortChange={(e) => {
            this.setState({
              sort: e.sort
            });
          }}
        >
          <Column field="new_hire_id.company_name" title="COMPANY" />
          <Column field="candidate_user_id.first_name" filter="numeric" format="" title="NAME" />
          <Column field="new_hire_id.role_title" filter="numeric" title="TITLE" />
          <Column field="new_hire_id.role_function" filter="numeric" title="FUNCTION" />
          <Column field="company_offer.0.total_dollar_value" filter="numeric" title="OFFER VALUE" />
          <Column field="new_hire_id.role_status" filter="numeric" title="STATUS" />
          <Column field="user_id.length" filter="numeric" title="RE-OFFER" />
          <Column cell={this.RoleButtons} title="ACTION" />
        </Grid>
      </div>
    );
  }
}


CandidateGrid.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  candidates: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  candidates: state.candidates
});

export default connect(mapStateToProps, { fnGetAllRoleCandidate }
)(CandidateGrid);