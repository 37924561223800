import React from 'react'
import '../../../../css/dashboard.css';

import { Link } from "react-router-dom";
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { DropDownButton,DropDownButtonItem  } from '@progress/kendo-react-buttons'
import OfferDashCandidateGridAddButton from './offerDashboardCandidateGridAddButton';
import { fnGetAllCandidatesFromOfferDashboard } from "../../../../actions/roleCandidateAction"
import  FileUpload   from './OfferUpload';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { candidateDataAction } from "../../../../actions/candidateOffersAction";

class OfferDashboardCandidateGrid extends React.Component {
  anchor = null;

  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
      CandidateList: [],
      success: false,
      skip: 0, take: 10, sort: [
        { field: 'role_title', dir: 'asc' }
      ]
    }
  }
  handleSelect = (e) => {
    this.setState({ selected: e.selected })
  }
  state = { skip: 0, take: 10 }

  pageChange = (event) => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  }

  componentDidMount() {
    if (this.props.location.search !== "") {
      let role_id = this.props.location.search.split('?user_id=')[1];
      this.props.fnGetAllCandidatesFromOfferDashboard(role_id);
    }

  }

onIconFlipOpen = (event) => {
    this.setState({
        popup_event: event,
        visible: false
    })
}


  render() {
    const itemRender1 = (props) => {
      return (
          <div onClick={() => this.onIconFlipOpen(`${props.item.value}`)}>
                                {`${props.item.text}`}
          </div >
      );
  };

    const items = [
      { text: 'UPLOAD EXPORT', value:'upload_report' },
      { text: 'DOWNLOAD REPORT'}
    ];

    if (this.props.candidates.offerCandidatesData === null) {
      this.state.CandidateList = []
    } else {
      this.state.CandidateList = this.props.candidates.offerCandidatesData

      if (this.props.location.search !== "") {


        if (this.props.candidates.offerCandidatesData[0] !== undefined) {

          var selected_candidate_total = this.props.candidates.offerCandidatesData[0]['candidate_user_id']['salary']['total_dollar_value']
          var offer_finalist = this.props.candidates.offerCandidatesData[0]['candidate_user_id']['salary']['total_dollar_value'] * 1000;
          var offer_total_dollar_value = new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(this.props.candidates.offerCandidatesData[0]['candidate_user_id']['salary']['total_dollar_value'])
          var offer_base = this.props.candidates.offerCandidatesData[0]['candidate_user_id']['salary']['base_salary']
          var offer_annual_bonus = this.props.candidates.offerCandidatesData[0]['candidate_user_id']['salary']['annual_bonus']
          var offer_equity_dollar_value = this.props.candidates.offerCandidatesData[0]['candidate_user_id']['salary']['equity_dollar_value']

          var candidate_pool = []
          var candidate_pool_base = []
          var candidate_pool_bonus = []
          var candidate_pool_Equity = []
          var candidate_pool_total_value = 0;
          var candidatePoolBaseValueTotal = 0;
          var candidatePoolBonusValueTotal = 0;
          var candidatePoolEquityValueTotal = 0;

          for (let i = 0; i < this.props.candidates.offerCandidatesData.length; i++) {
            candidate_pool = this.props.candidates.offerCandidatesData[i]['candidate_user_id']['salary']['total_dollar_value']
            candidate_pool_base =
              this.props.candidates.offerCandidatesData[i]['company_offer'][0]['base_value']
            candidate_pool_bonus =
              this.props.candidates.offerCandidatesData[i]['company_offer'][0]['annual_bonus_value']
            candidate_pool_Equity =
              this.props.candidates.offerCandidatesData[i]['company_offer'][0]['equity_dollar_value']

            candidate_pool_total_value += parseInt(candidate_pool);
            candidatePoolBaseValueTotal += parseInt(candidate_pool_base);
            candidatePoolBonusValueTotal += parseInt(candidate_pool_bonus);
            candidatePoolEquityValueTotal += parseInt(candidate_pool_Equity);
          }
          var candidate_pool_line_chart_cash = candidatePoolBaseValueTotal + candidatePoolBonusValueTotal
          var candidate_pool_line_chart_Equity = candidatePoolEquityValueTotal
          var candidate_pool_line_chart_Total = candidate_pool_line_chart_cash + candidate_pool_line_chart_Equity
          var offer_base_percentile = '75'
          var offer_bonus_percentile = '75'
          var offer_Equity_percentile = '75'
          var offer_offerStrength = selected_candidate_total / (candidate_pool_total_value / this.props.candidates.offerCandidatesData.length)

          if (offer_offerStrength < 0.9) {
            offer_offerStrength = offer_offerStrength * 0.6 * 100;
          } else if (0.9 <= offer_offerStrength < 1.0) {
            offer_offerStrength = offer_offerStrength * 0.9 * 100;
          } else if (1 <= offer_offerStrength <= 1.2) {
            offer_offerStrength = 92;
          } else if (offer_offerStrength > 1.2) {
            offer_offerStrength = 95;
          }
          var offer_recommended_total = 0
          if (selected_candidate_total >= candidate_pool_total_value) {
            offer_recommended_total = 1.10 * selected_candidate_total;
          } else {
            offer_recommended_total = 1.15 * selected_candidate_total;
          }
          this.props.candidateDataAction(candidate_pool_total_value, offer_total_dollar_value, offer_base, offer_annual_bonus, offer_equity_dollar_value, candidate_pool_line_chart_cash, candidate_pool_line_chart_Equity, candidate_pool_line_chart_Total, offer_finalist, offer_offerStrength, offer_recommended_total)
        }
      } else {
        this.props.candidateDataAction('0', '0', null, null, null, '', '', '', '0', 'offer_offerStrength', '0', 0, 0, 0)
      }
    }

    const itemRender = (props) => {
      return (
        <div style={{ width: "150px" }}>
          <span role="presentation" />
          {`${props.item.text}`}
        </div>
      );
    };
    if (this.props.location.search !== "") {
      var AddButtonURL = this.props.location.pathname + this.props.location.search
    }

    return (
      <React.Fragment>
        <div>
        {this.state.popup_event === "upload_report" ?
                    <div>{<FileUpload />}</div> : null
                }
 
          <div className="row " style={{ paddingTop: "0px", marginTop: "30px", borderTop: "1px solid #d9d9d9" }}>
            <div className="col-md-12 div-offer-candidate-grid">
              <h3 className="offer-candidate-h3"> Candidates <small className="offer-candidate-h3-small">profiles</small>
                <div className="col-md-2 float-right offer-candidate-popup-button" style={{ paddingRight: "0px" }} title="more">
                  <DropDownButton icon="k-icon k-i-more-vertical" dir="rtl" itemRender={itemRender1}
                    items={items}
                    title="more" />
                </div>

                <Link to={AddButtonURL}>
                  <span data-toggle="modal" data-target="#myModal" title="Add" className="k-icon k-i-plus k-icon-0 float-right offer-candidate-h3-icon-plus">

                  </span>
                </Link>
              </h3>
            </div>
            <OfferDashCandidateGridAddButton data-target="#myModal" location={this.props.location} history={this.props.history} />
            <div className="col-md-12">
              <Grid
                style={{ border: "0px" }}
                className=""
                data={this.state.CandidateList.slice(this.state.skip, this.state.take + this.state.skip)}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.CandidateList.length}
                pageable={true}
                onPageChange={this.pageChange}
              >
                <Column field="candidate_user_id.experience.company" title="COMPANY" />
                <Column field="candidate_user_id.first_name" filter="numeric" format="" title="NAME" />
                <Column field="candidate_user_id.overall_experience.title_level" filter="numeric" title="TITLE" />
                <Column field="candidate_user_id.overall_experience.job_function" filter="numeric" title="FUNCTION" />
                <Column field="company_offer.0.total_dollar_value" filter="numeric" format="{0:c}" title="TOTAL" />
                <Column field="company_offer.0.base_value" filter="numeric" format="{0:c}" title="BASE ($)" />
                <Column field="company_offer.0.annual_bonus_value" filter="numeric" format="{0:c}" title="BONUS ($)" />
                <Column field="company_offer.0.equity_dollar_value" filter="numeric" format="{0:c}" title="EQUITY ($)" />
                <Column field="company_offer.0.equity_percent" filter="numeric" title="EQUITY (%)" />

              </Grid>

            </div>
            {/* ---- */}

            {/* ---- */}
          </div>
        </div>
      </React.Fragment>

    )
  }
}

OfferDashboardCandidateGrid.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  offerCandidatesData: PropTypes.object.isRequired
};

const mapStateToProps = state =>
  ({
    auth: state.auth,
    offerCandidatesData: state.offerCandidatesData,
    candidates: state.candidates,
  });

export default connect(mapStateToProps, {
  fnGetAllCandidatesFromOfferDashboard,
  candidateDataAction
}
)(OfferDashboardCandidateGrid);
