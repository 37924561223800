import * as React from 'react';
import { Link } from "react-router-dom";
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import '../../../css/Addhrform.css';
import FormValidator from '../../../validation/FormValidator'
import validationParams from '../../../validation/validationParams.json'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fnCandidateOfferData } from "../../../actions/candidateOffersAction";

export class DesireOffer extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(validationParams.DesireOfferValidations);
    this.state = {
      success: false,
      base_value: "",
      annual_bonus_value: "",
      equity_dollar_value: "",
      total_dollar_value: "",
      message: "",
      validation: this.validator.valid()
    };
    this.submitted = false;
    this.OfferFormSubmit = this.OfferFormSubmit.bind(this);
  }
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

  };
  OfferFormSubmit = (event) => {
    event.preventDefault();

    let candidateOfferData = {
      base_value : this.state.base_value,
      annual_bonus_value : this.state.annual_bonus_value,
      equity_dollar_value : this.state.equity_dollar_value,
      total_dollar_value : this.state.total_dollar_value,
      id : this.props.match.params.id,
      companyOfferId : this.props.match.params.companyOfferId
    }

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      this.props.fnCandidateOfferData(candidateOfferData, this.props.history);
    }
  }

  render() {
    let Totalvalue = this.state.base_value + this.state.annual_bonus_value +
      this.state.equity_dollar_value
    this.state.total_dollar_value = Totalvalue;

    let validation = this.submitted ?
      this.validator.validate(this.state) :
      this.state.validation
    return (
      <div>
        <div className="container-fluid card-layout" style={{ width: "70%" }}>
          <div className="row card-block">
            <div className="col-sm-12 col-md-12 col-xs-12 col-lg-12">
              <form className="k-form" onSubmit={this.OfferFormSubmit} >
                <fieldset style={{ marginBottom: "0px" }}>
                  <h1 className="h1s">DESIRED OFFER:</h1>
                  <h3 className="h3s"> What is the minimum compensation range you seek for the following role? </h3>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">

                      <NumericTextBox
                        name="base_value"
                        type="text"
                        spinners={false}
                        style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                        onChange={this.onChange}
                        label="Base Salary *"
                        value={this.state.base_value || null}
                      />
                      <div style={{ color: "red" }}>
                        {validation.base_value.message}
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">

                      <NumericTextBox
                        name="annual_bonus_value"
                        type="text"
                        spinners={false}
                        onChange={this.onChange}
                        style={{ width: '100%' }}
                        label="Annual Bonus "
                        value={this.state.annual_bonus_value || null}

                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">

                      <NumericTextBox
                        name="equity_dollar_value"
                        type="text"
                        spinners={false}
                        style={{ width: '100%', color: "rgba(0,0,0,0.87)" }}
                        onChange={this.onChange}
                        label="Annual Equity Value"
                        value={this.state.equity_dollar_value || null}

                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-md-1 col-sm-1 col-xs-1 col-lg-1"></div>
                    <div className="col-md-4 col-sm-11 col-xs-11 col-lg-4">
                      <NumericTextBox
                        name="total_dollar_value"
                        type="text"
                        spinners={false}
                        disabled
                        onChange={this.onChange}
                        style={{ width: '100%' }}
                        label="Total Value"
                        value={this.state.total_dollar_value || null}

                      />
                    </div>
                  </div>
                  <h3 className="h3s"><b>Submit:</b> </h3>
                  <h3 className="h3s"><b>"Desired offer"for a role is submitted,USER is taken to RATE OFFER  dashboard:</b></h3>
                </fieldset>
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2">
                    <Button className="mt-3 col-md-12 col-xs-12 col-sm-12 col-lg-12" type="submit" primary={true}>Submit</Button>
                  </div>
                  <div className="col-md-2 col-xs-6 col-sm-6 col-lg-2" style={{ border: "1px slid red" }}>
                    <Link type="button" to="/home/my-offers" className="k-button col-md-12 col-xs-12 col-sm-12 col-lg-12" style={{ marginTop: "16px" }}>CANCEL</Link>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
DesireOffer.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  DesireOffer: state.DesireOffer,
  lookups: state.lookups,
  errors: state.errors
});

export default connect(mapStateToProps, { fnCandidateOfferData }
  )(DesireOffer);

