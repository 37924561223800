import axios from "axios";
import {
  SET_CURRENT_HIRING_TEAM_MEMBERS,
  SET_INSERT_UPDATE_HIRING_TEAM_MEMBERS,
  GET_HIRING_TEAM_MEMBERS,
  CLEAR_ERRORS
} from "./types";


export const fnGetAllHiringTeamMembers = (data) => dispatch => {
  dispatch(clearErrors());

  axios
    .get("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-hr-members-get-grid?hr_id=" + data.hr_id + '&new_hire_id=' + data.new_hire_id)
    .then(res => {
      const HiringMembersListData = res.data.response;
      const HiringMembersList = []
      for (let i = 0; i < HiringMembersListData.length; i++) {
        HiringMembersList.push(HiringMembersListData[i])
      }
      dispatch(setGetAllHiringTeamMembers(HiringMembersList));
    })
    .catch(err => {
      console.log(err)
    }
    );
};

export const FnInsertHiringTeamMembers = (insertRoleHr, history, new_hire_id) => dispatch => {
  dispatch(clearErrors());

  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-hr-member-post-insert", insertRoleHr)
    .then(res => {
      const roleHrInsertData = res.data;
      dispatch(setInsertHiringTeamMember(roleHrInsertData));
      if(history === "offer_dashboard_invite"){
        console.log(history)
      }else{
        history.push("/home/add-hr?id=" + new_hire_id)
      }
      
    })
    .catch(err => {
      console.log(err)
    }
    );
};


export function fnGetRoleHiringTeamMember(gerRoleHRData) {
  return axios
    .get('https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-hr-members-get-form?id=' + gerRoleHRData.id + '&new_hire_id=' + gerRoleHRData.new_hire_id)
}

export function fnDeleteHiringTeamMember(data) {
  return axios
    .delete("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-hiring-team-del?invitee_hiring_member_id="+data.invitee_hiring_member_id+"&new_hire_id="+data.new_hire_id)
}


export const FnUpdateHiringTeamMembers = (insertRoleHr, history, new_hire_id) => dispatch => {
  dispatch(clearErrors());

  axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-hiring-team-post-update", insertRoleHr)
    .then(res => {
      const roleHrUpdateData = res.data;
      dispatch(setInsertHiringTeamMember(roleHrUpdateData));
      history.push("/home/add-hr?id=" + new_hire_id)
    })
    .catch(err => {
      console.log(err)
    }
    );
};
//UserProfile
export const setCurrentHiringTeamMember = (users) => {
  return {
    type: SET_CURRENT_HIRING_TEAM_MEMBERS,
    payload: users
  };
};

//Insert Role HR
export const setInsertHiringTeamMember = (users) => {
  return {
    type: SET_INSERT_UPDATE_HIRING_TEAM_MEMBERS,
    payload: users
  };
};
//GetAllProfile
export const setGetAllHiringTeamMembers = (users) => {
  return {
    type: GET_HIRING_TEAM_MEMBERS,
    payload: users
  };
};

// Save offers from create offer dashboard
export function fnSaveOfferFromCreateOfferDashboard(userData) {
  return axios
    .post("https://55wqpnop60.execute-api.us-east-1.amazonaws.com/latest/apl-hrt-role-create-offer-post-insert", userData)
}

// clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};